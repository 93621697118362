import { useAuth0 } from '@auth0/auth0-react';
import React from 'react';
import { LDProvider as LDSDKProvider } from 'launchdarkly-react-client-sdk';
import useLDHash from 'hooks/queries/useLDHash';
import useConfig from 'hooks/useConfig';
import useLDUser, { LDUser } from 'hooks/useLDUser';

export type Props = {
    children: React.ReactNode;
};

const LDProvider = ({ children }: Props) => {
    const { isAuthenticated } = useAuth0();
    const { config, loading: loadingConfig } = useConfig();
    const user: LDUser | undefined = useLDUser();
    // Delay network call until the user is authenticated.
    const { data: hash, isLoading: hashIsLoading } = useLDHash({ enabled: isAuthenticated });

    /*
        With deferInitialization set to true, the LDProvider will not be rendered until the user is set,
        so we have to ensure everything is loaded before setting the user.
    */
    const ready = !loadingConfig && Boolean(user) && !hashIsLoading;

    return (
        // @ts-ignore
        <LDSDKProvider
            clientSideID={config?.LAUNCH_DARKLY_CLIENT_SIDE_ID}
            user={ready ? user : undefined}
            options={{ hash }}
            deferInitialization
        >
            {children}
        </LDSDKProvider>
    );
};

export default LDProvider;
