import { RecursivePartial } from '@mailupinc/bee-plugin/dist/types/utils';
import { FormControlLabel, Stack, Switch } from '@mui/material';
import { DateRange } from '@mui/x-date-pickers-pro';
import { SwitchInputLabel } from 'domains/core/components/InputLabel';
import { Value } from 'domains/core/components/Select';
import {
    RelativeDateRangeDirection,
    RelativeDateRange as RelativeDateRangeType,
    RelativeDateRangeValueAndUnit,
    SegmentFilter,
    SegmentFilterDemographicFields,
    SegmentFilterEventCondition,
    SegmentFilterEventFields,
    SegmentFilterState,
    isDateRangeOperator as isDateRangeOperatorType,
    isDateRangeType,
    isMultiInputOperator as isMultiInputOperatorType,
    isPercentileOperator as isPercentileOperatorType,
    isRelativeDateRangeType,
} from 'domains/segments/types';
import { isRelativeDateRangeLast, isRelativeDateRangeNext, isRelativeDateRangeToday } from 'domains/segments/utils';
import useContactFieldsTopValues from 'hooks/queries/useContactFieldsTopValues';
import omit from 'lodash/omit';
import { DateTime } from 'luxon';
import { SQLType } from 'models/enums';
import { useEffect, useState } from 'react';
import { getInitialDateRangeValue, getInitialDateValue, getRelativeDateRangeOffset } from '../utils';
import AutocompleteInput from './AutocompleteInput';
import DatePickerInput from './DatePickerInput';
import DateRangePickerInputs from './DateRangePickerInputs';
import DateTimePickerInput from './DateTimePickerInput';
import DateTimeRangePickerInputs from './DateTimeRangePickerInputs';
import PercentageInput from './PercentageInput';
import RelativeDateRange from './RelativeDateRange';
import TextFieldInput from './TextFieldInput';

export type Props = {
    activeCondition?: SegmentFilterEventCondition;
    filterOption: SegmentFilter;
    filterState: SegmentFilterState<SegmentFilterEventFields | SegmentFilterDemographicFields>;
    handleDatetimeError: (filterId: string, error: boolean) => void;
    handleChangeValue: (value: any) => void;
    handleChangeAutocompleteInputValue: (autocompleteInputValue: string, isMultiple: boolean) => void;
    handleChangeAutocompleteValue: (autocompleteValue: any, resetPreview?: boolean) => void;
    handleChangeAutocompleteValues?: (autocompleteValue?: any, resetPreview?: boolean) => void;
};

export type RelativeDateRangeArgs = {
    relativeDateRange?: RecursivePartial<RelativeDateRangeType>;
    unit?: RelativeDateRangeValueAndUnit['unit'];
};

const FilterValueInput = ({
    activeCondition,
    filterOption,
    filterState,
    handleDatetimeError,
    handleChangeValue,
    handleChangeAutocompleteInputValue,
    handleChangeAutocompleteValue,
}: Props) => {
    const { displayName, sqlType, valuesRef } = filterOption;

    const { data: contactFieldsTopValues } = useContactFieldsTopValues(valuesRef);

    const contactFieldsTopValuesOptions: Value[] = contactFieldsTopValues?.map(({ value, displayValue }) => ({
        label: displayValue,
        value,
    }));

    const filterId = filterState.id.toString();
    const operator = activeCondition ? activeCondition.operator : filterState.operator;
    const value = activeCondition ? activeCondition.value : filterState.categorySpecificFields?.value;
    const autocompleteInputValue = activeCondition
        ? activeCondition?.autocompleteInputValue
        : filterState.autocompleteInputValue;
    const autocompleteValue = activeCondition?.autocompleteValue || filterState.autocompleteValue;

    const isDateRangeOperator = isDateRangeOperatorType(operator);
    const isPercentileOperator = isPercentileOperatorType(operator);
    const isMultiInputOperator = isMultiInputOperatorType(operator);

    const isAutocomplete = contactFieldsTopValues?.length || isMultiInputOperator;
    const isDate = sqlType === SQLType.DATE;
    const isDateTime = sqlType === SQLType.TIMESTAMP_WITH_TIME_ZONE;

    const isDateTimeRangeValue = isDateRangeType(value);
    const isRelativeDateRangeValue = isRelativeDateRangeType(value);

    const durationUnit = isRelativeDateRangeValue ? value.duration.unit : null;
    const durationValue = isRelativeDateRangeValue ? value.duration.value : null;

    const valueDateTime = (typeof value === 'string' && DateTime.fromISO(value)) ?? null;
    const valueDateTimeStart = isDateTimeRangeValue ? DateTime.fromISO(value.start) : null;
    const valueDateTimeEnd = isDateTimeRangeValue ? DateTime.fromISO(value.end) : null;

    let valueRelativeDateRangeDirection: RelativeDateRangeDirection;
    if (isRelativeDateRangeLast(value) || isRelativeDateRangeToday(value)) {
        valueRelativeDateRangeDirection = RelativeDateRangeDirection.THE_LAST;
    } else if (isRelativeDateRangeNext(value)) {
        valueRelativeDateRangeDirection = RelativeDateRangeDirection.THE_NEXT;
    }

    const [isRelativeDateRangeChecked, setIsRelativeDateRangeChecked] = useState(isRelativeDateRangeValue);
    const [relativeDateRangeDirection, setRelativeDateRangeDirection] = useState(valueRelativeDateRangeDirection);

    // Prevents the need for the user to re-select the default date & time for enabling the preview button
    useEffect(() => {
        const defaultDate = DateTime.fromISO(getInitialDateValue(value));
        const defaultDateStart = DateTime.fromISO(getInitialDateRangeValue(value, 'start'));
        const defaultDateEnd = DateTime.fromISO(getInitialDateRangeValue(value, 'end'));

        if (isDateRangeOperator && isRelativeDateRangeChecked) {
            const defaultValue = {
                offset: getRelativeDateRangeOffset(valueRelativeDateRangeDirection, durationValue),
                duration: {
                    value: durationValue,
                    unit: durationUnit ?? 'day',
                },
            };
            handleRelativeDateRangeChange({ relativeDateRange: defaultValue });
            setRelativeDateRangeDirection(valueRelativeDateRangeDirection);
        } else if (isDateRangeOperator && isDate) {
            handleDateRangeChange([defaultDateStart, defaultDateEnd]);
        } else if (isDateRangeOperator && isDateTime) {
            const defaultValue = {
                start: defaultDateStart.toISO(),
                end: defaultDateEnd.toISO(),
            };
            handleChangeValue(defaultValue);
        } else if (isDate || isDateTime) {
            handleDateTimeChange(defaultDate, isDate);
            setIsRelativeDateRangeChecked(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isDate, isDateRangeOperator, isDateTime, isRelativeDateRangeChecked, isRelativeDateRangeValue, operator]);

    const handleDateRangeChange = (dateRange: DateRange<DateTime>) => {
        const [dateRangeStart, dateRangeEnd] = dateRange;

        const isValidDates = dateRangeStart.isValid && dateRangeEnd.isValid;
        const isValidDateStartEnd = dateRangeStart <= dateRangeEnd;
        const isValidDateRange = isValidDates && isValidDateStartEnd;

        const newValue = {
            start: dateRangeStart.toFormat('yyyy-MM-dd'),
            end: dateRangeEnd.toFormat('yyyy-MM-dd'),
        };

        handleChangeValue(newValue);
        handleDatetimeError(filterId, !isValidDateRange);
    };

    const handleDateTimeRangeChange = (datetime: DateTime, isStart: boolean) => {
        const isValidDate = datetime.isValid;
        const isValidDateStartEnd = isStart ? datetime < valueDateTimeEnd : datetime > valueDateTimeStart;
        const isValidDateRange = isValidDate && isValidDateStartEnd;

        const newValue = {
            ...(isDateTimeRangeValue && value),
            ...(isStart ? { start: datetime.toISO() } : { end: datetime.toISO() }),
        };

        handleChangeValue(newValue);
        handleDatetimeError(filterId, !isValidDateRange);
    };

    const handleIsRelativeDateRangeCheckedChange = () => setIsRelativeDateRangeChecked(!isRelativeDateRangeChecked);

    const handleRelativeDateRangeChange = ({ relativeDateRange, unit }: RelativeDateRangeArgs) => {
        // Spread in existing offset/duration keys as both don't have to be provided.
        const existingValues = omit(value as object, ['start', 'end']) as RelativeDateRangeType;

        const newOffset = {
            offset: {
                value: relativeDateRange?.offset ? relativeDateRange.offset.value : existingValues.offset.value,
                unit: unit ?? existingValues?.offset?.unit,
            },
        };
        const newDuration = {
            duration: {
                value: relativeDateRange?.duration ? relativeDateRange.duration.value : existingValues.duration.value,
                unit: unit ?? existingValues?.duration?.unit,
            },
        };

        const newValue = {
            ...existingValues,
            ...newOffset,
            ...newDuration,
        };

        handleChangeValue(newValue);
    };

    const handleDateTimeChange = (datetime: DateTime, isDate: boolean) => {
        const isValidDate = datetime.isValid;
        const newValue = isDate ? datetime.toFormat('yyyy-MM-dd') : datetime.toISO();

        handleChangeValue(newValue);
        handleDatetimeError(filterId, !isValidDate);
    };

    // TODO: combine handling date, datetime, date range, datetime range, and relative date range into single component with sub components
    // TODO: make this conditional render for date range & relative date range into a sub component
    if (isDate && isDateRangeOperator) {
        return (
            <Stack rowGap={1}>
                {isRelativeDateRangeChecked ? (
                    <RelativeDateRange
                        handleRelativeDateRangeChange={handleRelativeDateRangeChange}
                        direction={relativeDateRangeDirection}
                        duration={durationValue}
                        setDirection={setRelativeDateRangeDirection}
                        timeframe={durationUnit}
                    />
                ) : (
                    <div data-testid="date-range-picker">
                        <DateRangePickerInputs
                            valueStart={valueDateTimeStart}
                            valueEnd={valueDateTimeEnd}
                            onDateRangeChange={handleDateRangeChange}
                        />
                    </div>
                )}
                <FormControlLabel
                    control={
                        <Switch
                            color="secondary"
                            size="small"
                            checked={isRelativeDateRangeChecked}
                            onChange={handleIsRelativeDateRangeCheckedChange}
                            sx={{ mx: 1 }}
                        />
                    }
                    label={
                        <SwitchInputLabel
                            label="Use relative date range"
                            labelIconTooltipText="Relative dates update dynamically as the calendar changes."
                        />
                    }
                />
            </Stack>
        );
    }

    if (isDateTime && isDateRangeOperator) {
        return (
            <Stack rowGap={1}>
                {isRelativeDateRangeChecked ? (
                    <RelativeDateRange
                        direction={relativeDateRangeDirection}
                        duration={durationValue}
                        handleRelativeDateRangeChange={handleRelativeDateRangeChange}
                        isDateTime
                        setDirection={setRelativeDateRangeDirection}
                        timeframe={durationUnit}
                    />
                ) : (
                    <DateTimeRangePickerInputs
                        valueStart={valueDateTimeStart}
                        valueEnd={valueDateTimeEnd}
                        onChangeValueStart={(dateTimeStart) => handleDateTimeRangeChange(dateTimeStart, true)}
                        onChangeValueEnd={(dateTimeEnd) => handleDateTimeRangeChange(dateTimeEnd, false)}
                    />
                )}
                <FormControlLabel
                    control={
                        <Switch
                            color="secondary"
                            size="small"
                            checked={isRelativeDateRangeChecked}
                            onChange={handleIsRelativeDateRangeCheckedChange}
                            sx={{ mx: 1 }}
                        />
                    }
                    label={
                        <SwitchInputLabel
                            label="Use relative date range"
                            labelIconTooltipText="Relative dates update dynamically as the calendar changes."
                        />
                    }
                />
            </Stack>
        );
    }

    if (isDate) {
        return <DatePickerInput value={valueDateTime} onChangeDate={(value) => handleDateTimeChange(value, true)} />;
    }

    if (isDateTime) {
        return <DateTimePickerInput value={valueDateTime} onChange={(value) => handleDateTimeChange(value, false)} />;
    }

    if (isPercentileOperator) {
        return <PercentageInput placeholderName={displayName} value={value} onChange={handleChangeValue} />;
    }

    if (isAutocomplete) {
        return (
            <AutocompleteInput
                autocompleteInputValue={autocompleteInputValue}
                autocompleteValue={isMultiInputOperator ? autocompleteValue : value}
                freeSolo
                label="Value"
                multiple={isMultiInputOperator}
                onChange={handleChangeAutocompleteValue}
                onInputChange={(autocompleteInputValue) =>
                    handleChangeAutocompleteInputValue(autocompleteInputValue, isMultiInputOperator)
                }
                options={contactFieldsTopValuesOptions}
                placeholderName={displayName}
                type={sqlType}
            />
        );
    }

    return (
        <TextFieldInput placeholderName={displayName} sqlType={sqlType} value={value} onChange={handleChangeValue} />
    );
};

export default FilterValueInput;
