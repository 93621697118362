import { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { List, Box } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { useDispatch, useSelector } from 'react-redux';
import {
    ArrowForwardIosRounded,
    ArrowBackIosRounded,
    AutoGraph,
    ContactPageOutlined,
    PaidOutlined,
    SupervisedUserCircleOutlined,
    SwapCalls,
} from '@mui/icons-material';
import Button from 'domains/core/components/Button';
import ContentIcon from '../SvgIcons/ContentIcon';
import NavigationButton, { NavigationButtonVariant } from './NavigationButton';
import { URLPaths } from 'models/enums';
import CuredLogoWithText from 'assets/images/svg/cured-logo-green.svg';
import CuredLogoNoText from 'assets/images/svg/cured-logo-green-no-text.svg';
import { maximizeLeftNavPanel, minimizeLeftNavPanel } from 'state/appVisual/appVisualActions';
import { Link } from 'react-router-dom';
import ProfileButton from './ProfileButton';

const useStyles = makeStyles((theme: Theme) => ({
    toggleNavIcon: {
        color: 'white',
        fontSize: '1rem',
    },
}));

export const NavigationPanel = () => {
    const dispatch = useDispatch();
    const isLeftNavPanelMaximized = useSelector((state: any) => state.appVisual.isLeftNavPanelMaximized);

    const { isAuthenticated, user } = useAuth0();
    const classes = useStyles();
    const [isHover, setIsHover] = useState(false);

    if (!isAuthenticated) {
        return null;
    }

    return (
        <>
            {/* Overlay of main page body when nav panel is expanded */}
            <Box
                hidden={!isLeftNavPanelMaximized}
                bgcolor="#95a6a733"
                position="fixed"
                left={0}
                top={0}
                width="100%"
                height="100%"
                zIndex={10}
                onClick={() => dispatch(minimizeLeftNavPanel(false))}
            />
            <Box hidden={!isLeftNavPanelMaximized}>
                {/* navigation panel when maximized */}
                <Box
                    height="100%"
                    width="15.25rem"
                    sx={{
                        backgroundColor: 'secondary.light',
                        borderLeftColor: 'secondary.light',
                        borderLeftStyle: 'solid',
                        borderLeftWidth: '0.5rem',
                    }}
                    position="fixed"
                    left={0}
                    top={0}
                    zIndex={11}
                    overflow="hidden"
                    flex={1}
                >
                    <List dense disablePadding>
                        <ProfileButton userEmail={user.email} />
                        <Box
                            display="flex"
                            flexDirection="row"
                            marginTop={2}
                            marginLeft={2}
                            marginRight={2}
                            marginBottom={10}
                        >
                            <Link to="/">
                                <img
                                    src={CuredLogoWithText}
                                    alt="Cured logo"
                                    height="32"
                                    width="100%"
                                    onClick={() => dispatch(minimizeLeftNavPanel(false))}
                                />
                            </Link>
                        </Box>
                        <NavigationButton
                            title="curations"
                            path={URLPaths.CURATIONS}
                            helperText="Setup, test, and schedule interactions."
                            icon={<SwapCalls />}
                            variant={NavigationButtonVariant.WIDE}
                        />
                        <NavigationButton
                            title="audiences"
                            path={URLPaths.SEGMENTS}
                            helperText="Filter contacts to create new audiences."
                            icon={<SupervisedUserCircleOutlined />}
                            variant={NavigationButtonVariant.WIDE}
                        />
                        <NavigationButton
                            title="contacts"
                            path={URLPaths.CONTACTS}
                            helperText="Browse existing contacts and upload new ones."
                            icon={<ContactPageOutlined />}
                            variant={NavigationButtonVariant.WIDE}
                        />
                        <NavigationButton
                            title="content"
                            path={URLPaths.CONTENT}
                            helperText="Create your own content or customize a template to fit your brand."
                            icon={<ContentIcon />}
                            variant={NavigationButtonVariant.WIDE}
                        />
                        <NavigationButton
                            title="value"
                            path={URLPaths.VALUE}
                            helperText="Track the impact of your marketing efforts."
                            icon={<PaidOutlined />}
                            variant={NavigationButtonVariant.WIDE}
                        />
                        <NavigationButton
                            title="insights"
                            path={URLPaths.INSIGHTS}
                            helperText="Monitor the performance of Curations and gather insights on contacts."
                            icon={<AutoGraph />}
                            variant={NavigationButtonVariant.WIDE}
                        />
                    </List>
                </Box>
                {/* navigation panel border when maximized */}
                <Box
                    height="100%"
                    width="0.25rem"
                    sx={{
                        backgroundColor: isHover ? 'secondary.containedHoverBackground' : 'secondary.main',
                        transitionDuration: '0.23s',
                        transitionProperty: 'background-color',
                        cursor: 'pointer',
                    }}
                    onMouseEnter={() => setIsHover(true)}
                    onMouseLeave={() => setIsHover(false)}
                    onClick={() => dispatch(minimizeLeftNavPanel(false))}
                    position="fixed"
                    left="15.75rem"
                    top={0}
                    zIndex={4}
                />
                {/* navigation panel toggle when maximized */}
                <Button
                    onClick={() => {
                        dispatch(minimizeLeftNavPanel(false));
                        setIsHover(false);
                    }}
                    onMouseOver={() => {
                        setIsHover(true);
                    }}
                    onMouseLeave={() => {
                        setIsHover(false);
                    }}
                    sx={{
                        backgroundColor: isHover ? 'secondary.containedHoverBackground' : 'secondary.main',
                        minWidth: '2rem',
                        maxWidth: '2rem',
                        minHeight: '2rem',
                        maxHeight: '2rem',
                        padding: '0rem',
                        borderRadius: '0.25rem 0 0 0.25rem',
                        position: 'fixed',
                        bottom: '4rem',
                        left: '13.75rem',
                        zIndex: 5,
                        '&:hover': {
                            backgroundColor: 'secondary.containedHoverBackground',
                        },
                    }}
                >
                    <ArrowBackIosRounded classes={{ root: classes.toggleNavIcon }} />
                </Button>
            </Box>
            {/* navigation panel when minimized */}
            <Box hidden={isLeftNavPanelMaximized}>
                <Box
                    height="100%"
                    width="4rem"
                    sx={{ backgroundColor: 'secondary.light' }}
                    position="fixed"
                    left={0}
                    top={0}
                    zIndex={5}
                    overflow="hidden"
                    flex={1}
                >
                    <List dense disablePadding>
                        <ProfileButton userEmail={user.email} />
                        <Box
                            display="flex"
                            flexDirection="row"
                            justifyContent="center"
                            height="100%"
                            marginTop={2}
                            marginBottom={10}
                        >
                            <Link to="/">
                                <img
                                    src={CuredLogoNoText}
                                    alt="Cured logo"
                                    width="32"
                                    height="32"
                                    onClick={() => dispatch(minimizeLeftNavPanel(false))}
                                />
                            </Link>
                        </Box>
                        <NavigationButton
                            path={URLPaths.CURATIONS}
                            helperText="curations"
                            icon={<SwapCalls />}
                            variant={NavigationButtonVariant.NARROW}
                        />
                        <NavigationButton
                            path={URLPaths.SEGMENTS}
                            helperText="audiences"
                            icon={<SupervisedUserCircleOutlined />}
                            variant={NavigationButtonVariant.NARROW}
                        />
                        <NavigationButton
                            path={URLPaths.CONTACTS}
                            helperText="contacts"
                            icon={<ContactPageOutlined />}
                            variant={NavigationButtonVariant.NARROW}
                        />
                        <NavigationButton
                            path={URLPaths.CONTENT}
                            helperText="content"
                            icon={<ContentIcon />}
                            variant={NavigationButtonVariant.NARROW}
                        />
                        <NavigationButton
                            path={URLPaths.VALUE}
                            helperText="value"
                            icon={<PaidOutlined />}
                            variant={NavigationButtonVariant.NARROW}
                        />
                        <NavigationButton
                            path={URLPaths.INSIGHTS}
                            helperText="insights"
                            icon={<AutoGraph />}
                            variant={NavigationButtonVariant.NARROW}
                        />
                    </List>
                </Box>
                {/* navigation panel border when minimized */}
                <Box
                    height="100%"
                    width="0.25rem"
                    sx={{
                        backgroundColor: isHover ? 'secondary.containedHoverBackground' : 'secondary.main',
                        transitionDuration: '0.23s',
                        transitionProperty: 'background-color',
                        cursor: 'pointer',
                    }}
                    onMouseEnter={() => setIsHover(true)}
                    onMouseLeave={() => setIsHover(false)}
                    onClick={() => dispatch(maximizeLeftNavPanel())}
                    position="fixed"
                    left="4rem"
                    top={0}
                    zIndex={4}
                />
                {/* navigation panel toggle when minimized */}
                <Button
                    onClick={() => {
                        dispatch(maximizeLeftNavPanel());
                        setIsHover(false);
                    }}
                    onMouseOver={() => {
                        setIsHover(true);
                    }}
                    onMouseLeave={() => {
                        setIsHover(false);
                    }}
                    sx={{
                        backgroundColor: isHover ? 'secondary.containedHoverBackground' : 'secondary.main',
                        minWidth: '2rem',
                        maxWidth: '2rem',
                        minHeight: '2rem',
                        maxHeight: '2rem',
                        padding: '0rem',
                        borderRadius: '0 0.25rem 0.25rem 0',
                        position: 'fixed',
                        bottom: '4rem',
                        left: '4rem',
                        zIndex: 5,
                        '&:hover': {
                            backgroundColor: 'secondary.containedHoverBackground',
                        },
                    }}
                >
                    <ArrowForwardIosRounded classes={{ root: classes.toggleNavIcon }} />
                </Button>
            </Box>
        </>
    );
};

export default NavigationPanel;
