import { DateRange } from '@mui/x-date-pickers-pro';
import { DateTime } from 'luxon';
import DateRangePicker from 'domains/core/components/DateRangePicker';

type Props = {
    valueStart: DateTime;
    valueEnd: DateTime;
    onDateRangeChange: (dateTime: DateRange<DateTime>) => void;
};

const DateRangePickerInputs = ({ valueStart, valueEnd, onDateRangeChange }: Props) => (
    <DateRangePicker
        label="Date"
        slotProps={{
            textField: {
                style: { width: '16rem' },
            },
        }}
        value={[valueStart, valueEnd]}
        onChange={onDateRangeChange}
    />
);

export default DateRangePickerInputs;
