import { FilterCategory } from 'models/enums';
import {
    DateRange,
    isDateRangeType,
    isRelativeDateRangeType,
    NullOperator,
    RelativeDateRange,
    SegmentFilterCategorySpecificField,
    SegmentFilterEngagementFields,
    SegmentFilterEventCondition,
    SegmentFilterEventFields,
    SegmentFilterState,
} from 'domains/segments/types';

const isValidActivityFilter = (filter: SegmentFilterState<SegmentFilterEngagementFields>) => {
    const { categorySpecificFields, operator } = filter;
    const { value, segmentLinkInput } = categorySpecificFields;

    let isSegmentLinkValue;

    if (segmentLinkInput) {
        isSegmentLinkValue = segmentLinkInput.value === '' || segmentLinkInput.value === undefined;
    }

    return !!operator && !!value && !isSegmentLinkValue;
};

const isValidDateRange = (value: DateRange) => !!value.start && !!value.end;
const isValidRelativeDateRange = (value: RelativeDateRange) => {
    const isValidDurationValue = !!value.duration.value || value.duration.value === 0;
    const isValidOffsetValue = !!value.offset.value || value.offset.value === 0;

    const isValidDurationUnit = !!value.duration.unit;
    const isValidOffsetUnit = !!value.offset.unit;

    return isValidDurationValue && isValidOffsetValue && isValidDurationUnit && isValidOffsetUnit;
};

const isValidValue = (value: any, operator: any) => {
    if (isDateRangeType(value)) return isValidDateRange(value);
    if (isRelativeDateRangeType(value)) return isValidRelativeDateRange(value);
    if (Array.isArray(value)) return !!value.length;
    if (operator === NullOperator.IS_NULL || operator === NullOperator.IS_NOT_NULL) return !value;
    return !!value || value === 0;
};

const isValidDetailsFilter = (filter: SegmentFilterState<SegmentFilterCategorySpecificField>) => {
    const { categorySpecificFields, name, operator } = filter;
    const { value } = categorySpecificFields;

    return !!name && !!operator && isValidValue(value, operator);
};

const isValidSubFilter = (subFilter: SegmentFilterEventCondition) => {
    const { name, operator, value } = subFilter;
    return !!name && !!operator && isValidValue(value, operator);
};

const isValidEventsFilter = (filter: SegmentFilterState<SegmentFilterEventFields>) => {
    const { categorySpecificFields, name, operator } = filter;
    const { value, conditions } = categorySpecificFields;

    const { dateRangeDateTimeOperator } =
        'dateRangeDateTimeOperator' in categorySpecificFields && categorySpecificFields;

    if (value === '' && !conditions?.length) {
        return !!operator && !!name;
    }

    if (dateRangeDateTimeOperator) {
        return !!value;
    }

    if (!!conditions?.length) {
        const invalidSubFilter = conditions.find((condition) => !isValidSubFilter(condition));
        return !invalidSubFilter;
    }
};

const isFilterValid = (filter: SegmentFilterState<SegmentFilterCategorySpecificField>) => {
    const { category } = filter;

    if (!category) return false;

    if (category === FilterCategory.CONTACT_DETAILS) {
        return isValidDetailsFilter(filter);
    }

    if (category === FilterCategory.CONTACT_ACTIVITY) {
        return isValidActivityFilter(filter as SegmentFilterState<SegmentFilterEngagementFields>);
    }

    if (category === FilterCategory.CONTACT_EVENTS) {
        return isValidEventsFilter(filter);
    }
};

export const isSegmentFiltersValid = (
    filters: SegmentFilterState<SegmentFilterCategorySpecificField>[],
    isRecordTypesEmpty: boolean
): boolean => {
    if (filters.length === 0) return !isRecordTypesEmpty;

    const hasInvalidFilters = filters.some((filter) => !isFilterValid(filter));

    return !hasInvalidFilters;
};
