import { Theme, Tooltip, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { CampaignStatus } from 'models/enums';
import { Campaign } from 'domains/campaigns/types';

export const CampaignStatusToSx: Record<string, SxProps<Theme>> = {
    [CampaignStatus.DRAFT]: {
        bgcolor: 'white',
        color: 'primary.main',
    },
    [CampaignStatus.SCHEDULED]: {
        bgcolor: 'white',
        color: 'primary.main',
    },
    [CampaignStatus.SENDING]: {
        bgcolor: 'warning.main',
        color: 'primary.main',
    },
    [CampaignStatus.COMPLETED]: {
        bgcolor: 'action.active',
        color: 'primary.contrastText',
    },
    [CampaignStatus.FAILED]: {
        bgcolor: 'error.main',
        color: 'primary.contrastText',
    },
    [CampaignStatus.CANCELED]: {
        bgcolor: 'primary.main',
        color: 'primary.contrastText',
    },
    [CampaignStatus.STOPPED]: {
        bgcolor: 'other.divider',
        color: 'primary.main',
    },
    [CampaignStatus.FINISHING]: {
        bgcolor: 'other.radish',
        color: 'primary.main',
    },
};

export const getTooltipTitle = (campaign: Campaign) => {
    const CampaignStatusToToolTipTitle = {
        [CampaignStatus.CANCELED]: 'Curation will not publish.',
        [CampaignStatus.COMPLETED]: ``,
        [CampaignStatus.DRAFT]: ``,
        [CampaignStatus.FAILED]: `Curation failed - contact support for details.`,
        [CampaignStatus.FINISHING]:
            'Qualified contacts within the flow will finish and no additional contacts will enter.',
        [CampaignStatus.SCHEDULED]: ``,
        [CampaignStatus.SENDING]: ``,
        [CampaignStatus.STOPPED]:
            'Qualified contacts within the flow will not move forward and the Curation will stop.',
    };

    return CampaignStatusToToolTipTitle[campaign.status];
};

export type Props = {
    campaign: Campaign;
};

const CampaignStatusText = ({ campaign }: Props) => {
    if (!campaign.status) {
        return null;
    }

    const title = getTooltipTitle(campaign);

    return (
        <Tooltip aria-label={title} data-testid="tooltip" title={title}>
            <Typography
                className="status-font"
                component="span"
                sx={{
                    ...CampaignStatusToSx[campaign.status],
                    borderRadius: '0.25rem',
                    textTransform: 'lowercase',
                    padding: '0.125rem 0.75rem 0.25rem 0.75rem',
                    whiteSpace: 'nowrap',
                }}
            >
                {CampaignStatus[campaign.status]}
            </Typography>
        </Tooltip>
    );
};

export default CampaignStatusText;
