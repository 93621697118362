import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import Button from 'domains/core/components/Button';

/**
 * Login button configured with Auth0 authentication.
 *
 * @returns The React node created by this component.
 */
const LoginButton = () => {
    const { loginWithRedirect } = useAuth0();

    return (
        <Button variant="contained" onClick={() => loginWithRedirect()}>
            Log In
        </Button>
    );
};

export default LoginButton;
