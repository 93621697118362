import DatePicker from 'domains/core/components/DatePicker';
import { DateTime } from 'luxon';

type Props = {
    value: DateTime;
    onChangeDate: (date: DateTime) => void;
};

const DatePickerInput = ({ value, onChangeDate }: Props) => (
    <DatePicker
        data-testid="date-picker"
        label="Date"
        value={value}
        onChange={onChangeDate}
        slotProps={{
            textField: {
                style: {
                    width: '12rem',
                },
            },
        }}
    />
);

export default DatePickerInput;
