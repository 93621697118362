import RefreshIcon from '@mui/icons-material/Refresh';
import { Table as MUITable, TableBody, TableCell, TableHead, TableRow, TableSortLabel } from '@mui/material';
import { makeStyles } from '@mui/styles';
import groupBy from 'lodash/groupBy';
import React, { useMemo } from 'react';
import { useTable, useFilters, useSortBy, useExpanded, useRowSelect, SortingRule } from 'react-table';
import { RotatingIconButton } from 'domains/core/components/IconButton';
import { SortDirection } from 'models/enums';
import TableHeadText from '../TableHeadText';
import useManualSort from './hooks/useManualSort';
import { ColumnOption, ColumnInstance } from './types';

const compareIgnoreCase = (a: string, b: string) => a?.localeCompare(b);

const useStyles = makeStyles((theme) => ({
    stickyRow: {
        '&:first-of-type': {
            borderTopLeftRadius: '.25rem',
            borderBottomLeftRadius: '.25rem',
            borderLeftColor: theme.palette.secondary.main,
            borderLeftStyle: 'solid',
            borderLeftWidth: '.25rem',
        },
        '&:last-child': {
            borderTopRightRadius: '.25rem',
            borderBottomRightRadius: '.25rem',
        },
        backgroundColor: theme.palette.other.tableBackground,
        textTransform: 'none',
        top: '3.5rem',
    },
}));

export type Props = {
    /**
     * The columns that make up the table as an array of column options (Must be memoized) - https://react-table.tanstack.com/docs/api/useTable#column-options
     */
    columns: ColumnOption[];
    /**
     * The data that makes up the table as an array of objects where the key corresponds to the accessor field in columns (Must be memoized)
     */
    data: object[];
    defaultSortingRules?: SortingRule<object>[];
    isRefetching?: boolean;
    /**
     *  A function that updates the state for the sort query in manual table sorting
     */
    onSort?: (columnId: string, direction: SortDirection) => void;
    /**
     * A boolean indicating whether the sorting logic should be handled manually rather than through React Table
     */
    manualSortBy?: boolean;
    /**
     * A function to render the row sub component when it is expanded
     */
    renderRowSubComponent?: (row: object) => JSX.Element;
    /**
     * If provided a refresh button will be rendered as the last table head cell and this function will be called when the button is clicked
     */
    refetch?: () => void;
    /**
     * A boolean as to whether this table will have a header that is "sticky" to the top of the page
     */
    stickyHeader?: boolean;
    /**
     * A boolean as to whether this table will have a header & specific row that is "sticky" to the top of the page
     */
    stickyRow?: boolean;
    /**
     * A function that takes in a "row" object and returns a boolean if that row is the "sticky" row. Use useCallback to memoize this function.
     */
    stickyRowFilter?: (row: any) => boolean;
    /**
     * The JSX Element rendered at the top of the Table
     */
    top?: React.ReactNode;
};

/**
 * A table component that uses react-table and Material UI to render data.
 * For more info on react-table see https://react-table.tanstack.com/docs/api/useTable
 * We are using a few of the optional hooks to expand the table functionality. To learn more read:
 *
 * useFilters - https://react-table.tanstack.com/docs/api/useFilters
 *
 * useSortBy - https://react-table.tanstack.com/docs/api/useSortBy
 *
 * useExpanded - https://react-table.tanstack.com/docs/api/useExpanded
 *
 * useRowSelect - https://react-table.tanstack.com/docs/api/useRowSelect
 *
 */
const Table = ({
    columns,
    data,
    isRefetching,
    manualSortBy = false,
    onSort,
    renderRowSubComponent,
    defaultSortingRules = [],
    refetch,
    stickyHeader = true,
    stickyRow,
    stickyRowFilter,
    top,
}: Props) => {
    const classes = useStyles();
    const { columns: useTableColumns, getTableBodyProps, getTableProps, headerGroups, prepareRow, rows } = useTable(
        {
            // @ts-ignore
            columns,
            data,
            initialState: {
                sortBy: defaultSortingRules,
            },
            manualSortBy,
            disableSortRemove: true,
            sortTypes: {
                // The default alphanumeric sort is not case insensitive, this fixes that.
                alphanumeric: (row1, row2, columnName) =>
                    compareIgnoreCase(row1.values[columnName], row2.values[columnName]),
            },
            autoResetSortBy: false,
            autoResetExpanded: false,
        },
        useFilters,
        useSortBy,
        useExpanded,
        useRowSelect
    );

    const customTableColumns = useTableColumns as ColumnInstance[];
    useManualSort(manualSortBy, customTableColumns, onSort);

    const groupedRows = useMemo(() => groupBy(rows, (row: any) => (stickyRowFilter ? stickyRowFilter(row) : false)), [
        rows,
        stickyRowFilter,
    ]);
    const stickyRows = groupedRows.true || [];
    const nonStickyRows = groupedRows.false || [];

    const stickyRowObj = stickyRows[0];

    if (stickyRowObj) {
        prepareRow(stickyRowObj);
    }

    return (
        <MUITable stickyHeader={stickyHeader} {...getTableProps()}>
            {top}
            <TableHead>
                {headerGroups.map((headerGroup: any) => (
                    <TableRow {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column: ColumnInstance, i: number) => {
                            const isLastHeader = i === headerGroup.headers.length - 1;
                            return (
                                <TableCell key={column.id} align={column.align} sx={column.sx}>
                                    <TableHeadText>
                                        {isLastHeader && refetch ? (
                                            <>
                                                Refresh
                                                <RotatingIconButton
                                                    aria-label={'Refresh'}
                                                    disabled={isRefetching}
                                                    handleClick={refetch}
                                                    Icon={RefreshIcon}
                                                    iconSx={{ fontSize: 18 }}
                                                    isRefetching={isRefetching}
                                                />
                                            </>
                                        ) : (
                                            column.render('Header')
                                        )}
                                    </TableHeadText>
                                    {column.canSort && (
                                        <TableSortLabel
                                            {...column.getSortByToggleProps()}
                                            direction={column.isSortedDesc ? 'desc' : 'asc'}
                                            active={column.isSorted}
                                        />
                                    )}
                                </TableCell>
                            );
                        })}
                    </TableRow>
                ))}
                {stickyRow && stickyRowObj && (
                    <TableRow {...stickyRowObj.getRowProps()}>
                        {stickyRowObj.cells.map((cell: any) => (
                            <TableCell {...cell.getCellProps()} align={cell.column.align} className={classes.stickyRow}>
                                {cell.render('Cell')}
                            </TableCell>
                        ))}
                    </TableRow>
                )}
            </TableHead>
            {nonStickyRows && (
                <TableBody {...getTableBodyProps()}>
                    {stickyRow &&
                        stickyRowObj &&
                        (stickyRowObj as any).isExpanded &&
                        renderRowSubComponent(stickyRowObj)}
                    {nonStickyRows.map((row: any) => {
                        prepareRow(row);
                        return (
                            <React.Fragment key={row.original.id}>
                                <TableRow {...row.getRowProps()} selected={row.getToggleRowSelectedProps().checked}>
                                    {row.cells.map((cell: any) => (
                                        <TableCell
                                            {...cell.getCellProps()}
                                            align={cell.column.align}
                                            sx={cell.column.sx}
                                        >
                                            {cell.render('Cell')}
                                        </TableCell>
                                    ))}
                                </TableRow>
                                {row.isExpanded && renderRowSubComponent(row)}
                            </React.Fragment>
                        );
                    })}
                </TableBody>
            )}
        </MUITable>
    );
};

export default Table;
