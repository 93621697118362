import { DateTimePicker } from '@mui/x-date-pickers';
import { getTimezone } from 'utils';
import { DateTime } from 'luxon';

type Props = {
    value: DateTime;
    onChange: (dateTime: DateTime) => void;
};

const DateTimePickerInput = ({ value, onChange }: Props) => (
    <DateTimePicker
        label={`Date/Time (${getTimezone()})`}
        value={value}
        onChange={onChange}
        slotProps={{
            textField: {
                style: {
                    width: '16rem',
                },
            },
        }}
    />
);

export default DateTimePickerInput;
