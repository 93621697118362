import { Stack } from '@mui/material';
import { AutocompleteOption } from 'domains/core/components/Autocomplete/Autocomplete';
import InputLabel from 'domains/core/components/InputLabel';
import Select, { Value } from 'domains/core/components/Select';
import {
    DemographicContainsOperator,
    DemographicContainsOperatorHelpText,
    NullOperator,
    SegmentDemographicValue,
    SegmentFilter,
    SegmentFilterDemographicFields,
    SegmentFilterState,
    isMultiInputOperator as isMultiInputOperatorType,
} from 'domains/segments/types';
import lowerCase from 'lodash/lowerCase';
import { SQLType } from 'models/enums';
import FilterValueInput from '../FilterValueInput';
import { getInitialDateValue, getNumericOrStringValue, getOperatorValues } from '../utils';
import { useFlags } from 'launchdarkly-react-client-sdk';

export type Props = {
    filterOptions: SegmentFilter[];
    filter: SegmentFilterState<SegmentFilterDemographicFields>;
    index: number;
    handleDatetimeError: (filterId: string, error: boolean) => void;
    updateSegmentFilter: (
        index: number,
        filter: SegmentFilterState<SegmentFilterDemographicFields>,
        resetPreview?: boolean
    ) => void;
};

const DemographicFilter = ({ filterOptions, filter, index, handleDatetimeError, updateSegmentFilter }: Props) => {
    const { categorySpecificFields, name, operator } = filter;
    const { nullOperator: isNullOperatorEnabled } = useFlags();
    const { value } = categorySpecificFields;

    const filterOption = filterOptions?.find((filterOption) => filterOption.name === name);

    const filterOptionsSorted: Value[] = filterOptions
        ?.sort((a, b) => a.displayName.localeCompare(b.displayName))
        .map((filter) => ({ label: lowerCase(filter.displayName), value: filter.name }));

    const isValidFilterName = filterOptionsSorted.map((filterOptionSorted) => filterOptionSorted.value).includes(name);
    const selectedName = isValidFilterName ? name : '';

    const operatorValues = getOperatorValues(filterOption?.sqlType, isNullOperatorEnabled);
    const operatorsHaveHelpText = filterOption?.sqlType === SQLType.CHARACTER_VARYING_ARRAY;
    const operatorValuesWithHelpText = operatorValues.map((operator) => ({
        label: (
            <InputLabel
                label={operator.label}
                labelIconTooltipText={
                    DemographicContainsOperatorHelpText[operator.label as DemographicContainsOperator]
                }
            />
        ),
        value: operator.value,
    }));
    const operators = operatorsHaveHelpText ? operatorValuesWithHelpText : operatorValues;

    const handleFilterChange = (e: any) => {
        const { autocompleteInputValue, autocompleteValue, ...restOfFilter } = filter;

        const newValue =
            filterOption?.sqlType === 'date' || filterOption?.sqlType === 'timestamp with time zone'
                ? getInitialDateValue(value)
                : '';

        updateSegmentFilter(index, {
            ...restOfFilter,
            categorySpecificFields: {
                value: newValue,
            },
            name: e.target.value,
            operator: null,
        });
    };

    const handleOperatorChange = (e: any) => {
        const { autocompleteInputValue, autocompleteValue, ...restOfFilter } = filter;
        const isMultiInputOperator = isMultiInputOperatorType(e.target.value);

        updateSegmentFilter(index, {
            ...restOfFilter,
            ...(isMultiInputOperator && { autocompleteValue: [] }),
            autocompleteInputValue: '',
            categorySpecificFields: {
                value: isMultiInputOperator ? [] : '',
            },
            operator: e.target.value,
        });
    };

    const handleChangeValue = (value: any) => {
        const { autocompleteInputValue, autocompleteValue, ...restOfFilter } = filter;
        const newValue = getNumericOrStringValue(value, filterOption?.sqlType);

        updateSegmentFilter(index, {
            ...restOfFilter,
            autocompleteInputValue: `${newValue}`,
            categorySpecificFields: {
                value: newValue,
            },
        });
    };

    const handleChangeAutocompleteValue = (
        autocompleteValue: any | AutocompleteOption<string>[],
        resetPreview?: boolean
    ) => {
        const getOption = (value: string | AutocompleteOption<string>) => {
            if (!!value && typeof value === 'object' && 'label' in value && 'value' in value) return value;
            return { label: value as string, value: value as string };
        };

        const hasMultipleValues = Array.isArray(autocompleteValue);

        const newAutocompleteInputValue = hasMultipleValues ? '' : autocompleteValue?.value ?? autocompleteValue;

        const newAutocompleteValue: AutocompleteOption<string> | AutocompleteOption<string>[] = hasMultipleValues
            ? autocompleteValue.map((autocompleteValue) => getOption(autocompleteValue))
            : getOption(autocompleteValue);

        const newValue = hasMultipleValues
            ? (newAutocompleteValue as AutocompleteOption<string>[]).map((autocompleteValue) =>
                  getNumericOrStringValue(autocompleteValue?.value, filterOption?.sqlType)
              )
            : getNumericOrStringValue(newAutocompleteInputValue, filterOption?.sqlType);

        updateSegmentFilter(
            index,
            {
                ...filter,
                categorySpecificFields: {
                    value: newValue as SegmentDemographicValue,
                },
                autocompleteInputValue: newAutocompleteInputValue,
                autocompleteValue: newAutocompleteValue,
            },
            resetPreview
        );
    };

    const handleChangeAutocompleteInputValue = (autocompleteInputValue: string, hasMultipleValues: boolean) => {
        const value = !hasMultipleValues && {
            categorySpecificFields: {
                value: autocompleteInputValue,
            },
        };

        updateSegmentFilter(index, {
            ...filter,
            ...value,
            autocompleteInputValue,
        });
    };

    return (
        <>
            <Stack direction="row" order="1">
                <Select
                    data-test="details-filter-select"
                    label="Filter"
                    sx={{ textTransform: 'capitalize', width: '16rem' }}
                    value={selectedName}
                    values={filterOptionsSorted}
                    onChange={handleFilterChange}
                />
            </Stack>
            {selectedName && (
                <Stack direction="row" order="2">
                    <Select
                        data-test="details-operator-select"
                        label="Operator"
                        sx={{ textTransform: 'capitalize', width: '12rem' }}
                        value={operator ?? ''}
                        values={operators}
                        onChange={handleOperatorChange}
                    />
                </Stack>
            )}
            {operator && filterOption && operator !== NullOperator.IS_NOT_NULL && operator !== NullOperator.IS_NULL && (
                <Stack direction="row" order="3">
                    <FilterValueInput
                        filterOption={filterOption}
                        filterState={filter}
                        handleDatetimeError={handleDatetimeError}
                        handleChangeValue={handleChangeValue}
                        handleChangeAutocompleteValue={handleChangeAutocompleteValue}
                        handleChangeAutocompleteInputValue={handleChangeAutocompleteInputValue}
                    />
                </Stack>
            )}
        </>
    );
};

export default DemographicFilter;
