import { useMemo } from 'react';
import { Position } from 'reactflow';
import {
    CallSplit,
    MailOutline,
    DoNotDisturbAlt,
    QueryBuilder,
    SupervisedUserCircleOutlined,
} from '@mui/icons-material';
import { SvgIconProps } from '@mui/material';
import theme from 'theme';
import { CurationSegmentsAction, URLPaths } from 'models/enums';
import { route as routeSelector } from 'state/selectors';
import {
    selectIsConditionalSplitRouteDisabled,
    selectConditionalSplitStateByRoute,
} from 'domains/campaigns/state/selectors';
import { EdgePropsPartial, iSegmentsActionState, NodePropsPartial } from 'domains/campaigns/types';
import LegacyCampaignNavBar from '../LegacyCampaignNavBar';
import { useAppSelector } from 'hooks/redux';

const edgeStyle = {
    labelBgBorderRadius: 4,
    labelShowBg: true,
    type: 'smoothstep',
};

const getLabelBgPadding = (highlighted: boolean): [number, number] => (highlighted ? [16, 4] : [12, 2]);

const getLabelStyle = (highlighted: boolean) => ({
    ...theme.typography.overline,
    ...(highlighted && { fontWeight: 700, fontSize: '14px' }),
    fill: theme.palette.action.active,
});

const getLabelBgStyle = (highlighted: boolean) => ({
    fill: 'white',
    fillOpacity: 1,
    stroke: theme.palette.action.active,
    strokeWidth: highlighted ? 2 : 1,
});

type Props = {
    readonly?: boolean;
    yesIsExit?: boolean;
    noIsExit?: boolean;
};

const ConditionalSplitNavBar = ({ readonly = false, yesIsExit = false, noIsExit = false }: Props) => {
    const getCurationStateByRoute = useAppSelector((state) => selectConditionalSplitStateByRoute(state));
    const route = useAppSelector((state) => routeSelector(state));

    const isRouteStartOrPublish =
        route === URLPaths.CURATIONS_CONDITIONAL_SPLIT || route === URLPaths.CURATIONS_CONDITIONAL_SPLIT_PUBLISH;

    const nodes: NodePropsPartial[] = useMemo(() => {
        const segmentsActionState = (route: URLPaths) => {
            const state = getCurationStateByRoute(route);
            const action = iSegmentsActionState(state) ? state.action : null;

            return action;
        };
        const segmentsActionLabel = (route: URLPaths, isExit: boolean) => {
            const action = segmentsActionState(route);

            if (action === CurationSegmentsAction.EXIT_CURATION || isExit) {
                return 'exit curation';
            }
            if (action === CurationSegmentsAction.SEND_EMAIL || readonly) {
                return 'email';
            }
            if (route === URLPaths.CURATIONS_CONDITIONAL_SPLIT_SEGMENTS_MEETS_ACTION) {
                return 'meets criteria';
            }

            return 'does not meet criteria';
        };
        const segmentsActionIcon = (route: URLPaths, isExit: boolean, props?: SvgIconProps) => {
            const action = segmentsActionState(route);

            if (action === CurationSegmentsAction.EXIT_CURATION || isExit) {
                return <DoNotDisturbAlt {...props} />;
            }
            if (
                action === CurationSegmentsAction.SEND_EMAIL ||
                readonly ||
                (route !== URLPaths.CURATIONS_CONDITIONAL_SPLIT_SEGMENTS_MEETS_ACTION &&
                    route !== URLPaths.CURATIONS_CONDITIONAL_SPLIT_SEGMENTS_MEETS_NOT_ACTION)
            ) {
                return <MailOutline {...props} />;
            }

            return <CallSplit {...props} />;
        };
        const nodesConditionalSplit: NodePropsPartial[] = [
            {
                data: {
                    label: 'audience',
                    leftIcon: (props: SvgIconProps) => <SupervisedUserCircleOutlined {...props} />,
                    routes: [URLPaths.CURATIONS_CONDITIONAL_SPLIT_SEGMENT],
                },
            },
            {
                data: {
                    label: 'email',
                    leftIcon: (props: SvgIconProps) => <MailOutline {...props} />,
                    routes: [
                        URLPaths.CURATIONS_CONDITIONAL_SPLIT_1_CONTENT,
                        URLPaths.CURATIONS_CONDITIONAL_SPLIT_SCHEDULE,
                    ],
                },
            },
            {
                data: {
                    label: 'time delay',
                    leftIcon: (props: SvgIconProps) => <QueryBuilder {...props} />,
                    routes: [URLPaths.CURATIONS_CONDITIONAL_SPLIT_DELAY],
                },
            },
            {
                data: {
                    label: 'conditional split',
                    leftIcon: (props: SvgIconProps) => <CallSplit {...props} />,
                    routes: [URLPaths.CURATIONS_CONDITIONAL_SPLIT_SEGMENTS],
                    hasSplit: true,
                },
            },
            {
                data: {
                    label: segmentsActionLabel(URLPaths.CURATIONS_CONDITIONAL_SPLIT_SEGMENTS_MEETS_ACTION, yesIsExit),
                    leftIcon: (props: SvgIconProps) =>
                        segmentsActionIcon(
                            URLPaths.CURATIONS_CONDITIONAL_SPLIT_SEGMENTS_MEETS_ACTION,
                            yesIsExit,
                            props
                        ),
                    routes: [
                        URLPaths.CURATIONS_CONDITIONAL_SPLIT_SEGMENTS_MEETS_ACTION,
                        ...(segmentsActionState(URLPaths.CURATIONS_CONDITIONAL_SPLIT_SEGMENTS_MEETS_ACTION) ===
                        CurationSegmentsAction.SEND_EMAIL
                            ? [URLPaths.CURATIONS_CONDITIONAL_SPLIT_SEGMENTS_MEETS_CONTENT]
                            : []),
                    ],
                    isSplitMeets: true,
                },
            },
            {
                data: {
                    label: segmentsActionLabel(
                        URLPaths.CURATIONS_CONDITIONAL_SPLIT_SEGMENTS_MEETS_NOT_ACTION,
                        noIsExit
                    ),
                    leftIcon: (props: SvgIconProps) =>
                        segmentsActionIcon(
                            URLPaths.CURATIONS_CONDITIONAL_SPLIT_SEGMENTS_MEETS_NOT_ACTION,
                            noIsExit,
                            props
                        ),
                    routes: [
                        URLPaths.CURATIONS_CONDITIONAL_SPLIT_SEGMENTS_MEETS_NOT_ACTION,
                        ...(segmentsActionState(URLPaths.CURATIONS_CONDITIONAL_SPLIT_SEGMENTS_MEETS_NOT_ACTION) ===
                        CurationSegmentsAction.SEND_EMAIL
                            ? [URLPaths.CURATIONS_CONDITIONAL_SPLIT_SEGMENTS_MEETS_NOT_CONTENT]
                            : []),
                    ],
                    isSplitMeetsNot: true,
                },
                targetPosition: Position.Right,
            },
        ];

        return nodesConditionalSplit;
    }, [getCurationStateByRoute, readonly, yesIsExit, noIsExit]);

    const edges: EdgePropsPartial[] = useMemo(() => {
        const isRouteSegmentsMeets =
            route === URLPaths.CURATIONS_CONDITIONAL_SPLIT_SEGMENTS_MEETS_ACTION ||
            route === URLPaths.CURATIONS_CONDITIONAL_SPLIT_SEGMENTS_MEETS_CONTENT;
        const isRouteSegmentsMeetsNot =
            route === URLPaths.CURATIONS_CONDITIONAL_SPLIT_SEGMENTS_MEETS_NOT_ACTION ||
            route === URLPaths.CURATIONS_CONDITIONAL_SPLIT_SEGMENTS_MEETS_NOT_CONTENT;

        const edgesConditionalSplit: EdgePropsPartial[] = [];
        nodes.forEach((node) => {
            if (node.data.hasSplit) {
                return;
            } else if (node.data.isSplitMeets) {
                edgesConditionalSplit.push({
                    label: 'yes',
                    labelBgPadding: getLabelBgPadding(isRouteSegmentsMeets),
                    labelBgStyle: getLabelBgStyle(isRouteSegmentsMeets),
                    labelStyle: getLabelStyle(isRouteSegmentsMeets),
                    ...edgeStyle,
                });
            } else if (node.data.isSplitMeetsNot) {
                edgesConditionalSplit.push({
                    label: 'no',
                    labelBgPadding: getLabelBgPadding(isRouteSegmentsMeetsNot),
                    labelBgStyle: getLabelBgStyle(isRouteSegmentsMeetsNot),
                    labelStyle: getLabelStyle(isRouteSegmentsMeetsNot),
                    ...edgeStyle,
                });
            } else {
                edgesConditionalSplit.push({});
            }
        });

        return edgesConditionalSplit;
    }, [route, nodes]);

    return (
        <LegacyCampaignNavBar
            isRouteStartOrPublish={isRouteStartOrPublish}
            selectCurationStateByRoute={selectConditionalSplitStateByRoute}
            selectIsCampaignRouteDisabled={selectIsConditionalSplitRouteDisabled}
            nodesFromCuration={nodes}
            edgesFromCuration={edges}
            readonly={readonly}
        />
    );
};

export default ConditionalSplitNavBar;
