import { Campaign } from 'domains/campaigns/types';
import { CampaignStatus } from 'models/enums';
import useUpdateCampaignStatus from 'hooks/mutations/useUpdateCampaignStatus';

export type Params = {
    campaign: Campaign;
    name: string;
    refetch: () => void;
    resetInfoMessage: () => void;
    setCampaign: (campaign: Campaign) => void;
    setErrorMessage: (errorMessage: string) => void;
    setInfoMessage: () => void;
    setSuccessMessage: () => void;
};

export const tooltipTitle: Record<string, string> = {
    cancel: 'Curation will not publish.',
    stop: 'Qualified contacts within the flow will not move forward and the Curation will stop.',
    finish: 'Qualified contacts within the flow will finish and no additional contacts will enter.',
};

const status: Record<string, string> = {
    cancel: CampaignStatus.CANCELED,
    stop: CampaignStatus.STOPPED,
    finish: CampaignStatus.FINISHING,
};

const useGetCampaignMenuItem = ({
    campaign,
    name,
    refetch,
    resetInfoMessage,
    setCampaign,
    setErrorMessage,
    setInfoMessage,
    setSuccessMessage,
}: Params) => {
    const nameLowerCased = name.toLowerCase();
    const statusLowerCased = status[nameLowerCased].toLowerCase();

    const onClick = () => {
        setCampaign(campaign);
        setInfoMessage();
        updateCampaignStatus();
    };

    const onSuccess = () => {
        setSuccessMessage();
        resetInfoMessage();
        refetch();
    };

    const { mutate: updateCampaignStatus } = useUpdateCampaignStatus(campaign.id, statusLowerCased, {
        onSuccess,
        onError: (error: Error) => setErrorMessage(error.message),
    });

    return {
        name,
        onClick,
        tooltipTitle: tooltipTitle[nameLowerCased],
    };
};

export default useGetCampaignMenuItem;
