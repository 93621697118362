import { useCallback, useMemo, useState } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useHistory } from 'react-router-dom';
import ErrorSnackbar from 'domains/core/components/Snackbars/ErrorSnackbar';
import SuccessSnackbar from 'domains/core/components/Snackbars/SuccessSnackbar';
import DateRangeText from 'domains/core/components/DateRangeText';
import Table, { ColumnOption } from 'domains/core/components/Table';
import MutationKeys from 'hooks/mutations/keys';
import useMutationStatus from 'hooks/useMutationStatus';
import { CampaignStatus, MutationStatus, URLPaths } from 'models/enums';
import { Campaign, PartialSend, SendRecurrenceFrequency } from 'domains/campaigns/types';
import CampaignStatusText from './CampaignStatusText';
import CampaignNameInput from './CampaignNameInput';
import Recurrence from 'domains/campaigns/components/Recurrence/Recurrence';
import MoreOptionsDropdown from 'domains/core/components/MoreOptionsDropdown';
import useGetCampaignMenuItem from './useGetCampaignMenuItem';
import ConfirmationDialog from 'domains/core/components/ConfirmationDialog';
import useDeleteCampaign from 'hooks/mutations/useDeleteCampaign';
import InfoSnackbar from 'domains/core/components/Snackbars/InfoSnackbar';

export type Props = {
    campaigns: Campaign<PartialSend>[];
    isRefetching: boolean;
    refetch: () => void;
};

/**
 * A table used for displaying curations in the curations page.
 *
 * @param campaigns The campaigns data passed in from CurationsPage.
 * @returns The React node created by this component.
 */
const CampaignsTable = ({ campaigns, isRefetching, refetch }: Props) => {
    const [errorMessage, setErrorMessage] = useState('');
    const [infoMessage, setInfoMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [campaign, setCampaign] = useState<Campaign>();
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

    const { curationPause: hasCurationPauseFeature } = useFlags();
    const history = useHistory();

    const deleteCampaignStatus = useMutationStatus(MutationKeys.DELETE_CAMPAIGN);
    const updateCampaignStatusStatus = useMutationStatus(MutationKeys.UPDATE_CAMPAIGN_STATUS);

    const { mutate: deleteCampaign } = useDeleteCampaign(campaign?.id, {
        onError: (error: Error) => setErrorMessage(error.message),
        onSuccess: () => {
            setSuccessMessage(`"${campaign.name}" successfully deleted.`);
            setInfoMessage('');
            refetch();
        },
    });

    const isDeleteError = deleteCampaignStatus === MutationStatus.ERROR;
    const isUpdateError = updateCampaignStatusStatus === MutationStatus.ERROR;

    const isDeleteSuccess = deleteCampaignStatus === MutationStatus.SUCCESS;
    const isUpdateSuccess = updateCampaignStatusStatus === MutationStatus.SUCCESS;

    const toggleDeleteModalOpen = useCallback(
        (campaign?: Campaign, isConfirm?: boolean) => {
            setIsDeleteModalOpen(!!campaign);
            isConfirm ? deleteCampaign() : setCampaign(campaign);
        },
        [setIsDeleteModalOpen, deleteCampaign, setCampaign]
    );

    const columns: ColumnOption[] = useMemo(
        () => [
            {
                Header: 'name',
                accessor: 'name',
                align: 'left',
                Cell: (row: any) => (
                    <CampaignNameInput id={row.cell.row.original.id} name={row.cell.value} onSave={refetch} />
                ),
            },
            {
                Header: 'status',
                accessor: 'status',
                align: 'center',
                Cell: (row: any) => {
                    const campaign = row.cell.row.original;

                    return <CampaignStatusText campaign={campaign} />;
                },
            },
            {
                Header: 'schedule',
                accessor: (row) => new Date(row.startTime),
                align: 'center',
                id: 'schedule',
                sortType: 'datetime',
                Cell: (row: any) => {
                    const campaign: Campaign = row.cell.row.original;
                    const { startTime, endTime } = campaign;

                    return (
                        <>
                            <DateRangeText startTime={startTime} endTime={endTime} />
                            <div data-test={`${campaign.name}-recurrence`}>
                                <Recurrence
                                    recurrenceFrequency={campaign.recurrenceFrequency as SendRecurrenceFrequency}
                                    startDatetime={startTime}
                                />
                            </div>
                        </>
                    );
                },
            },
            {
                Header: 'type',
                accessor: 'channel',
                align: 'center',
                Cell: (row: any) => {
                    const campaign: Campaign = row.cell.row.original;

                    return <p>{campaign.channel.toLowerCase()}</p>;
                },
            },
            {
                Header: '',
                accessor: 'options',
                align: 'right',
                disableSortBy: true,
                Cell: (value: any) => {
                    const campaign: Campaign = value.row.original;

                    const sharedMenuItemProps = {
                        campaign,
                        refetch,
                        resetInfoMessage: () => setInfoMessage(''),
                        setCampaign,
                        setErrorMessage,
                    };
                    const campaignNameString = `"${campaign.name}"`;
                    const cancelCampaignMenuItem = useGetCampaignMenuItem({
                        name: 'Cancel',
                        setInfoMessage: () => setInfoMessage(`Canceling ${campaignNameString}`),
                        setSuccessMessage: () => setSuccessMessage(`${campaignNameString} canceled.`),
                        ...sharedMenuItemProps,
                    });
                    const stopCampaignMenuItem = useGetCampaignMenuItem({
                        name: 'Stop',
                        setInfoMessage: () => setInfoMessage(`Stopping ${campaignNameString}`),
                        setSuccessMessage: () => setSuccessMessage(`${campaignNameString} stopped.`),
                        ...sharedMenuItemProps,
                    });
                    const finishCampaignMenuItem = useGetCampaignMenuItem({
                        name: 'Finish',
                        setInfoMessage: () => setInfoMessage(`Finishing ${campaignNameString}`),
                        setSuccessMessage: () => setSuccessMessage(`${campaignNameString} is finished.`),
                        ...sharedMenuItemProps,
                    });
                    const deleteCampaignMenuItem = {
                        name: 'Delete',
                        onClick: () => toggleDeleteModalOpen(campaign),
                    };
                    const pauseCampaignMenuItem = {
                        name: 'Pause',
                        onClick: (): null => null,
                        tooltipTitle: 'Temporarily stop contacts where they are in the Curation flow.',
                    };
                    const viewDetailsMenuItem = {
                        name: 'View Details',
                        onClick: () => history.push(`${URLPaths.CURATIONS}/${campaign.id}/summary`),
                    };

                    const getMenuItems = () => {
                        const isCampaignSending = campaign.status === CampaignStatus.SENDING;
                        const isCampaignFinishing = campaign.status === CampaignStatus.FINISHING;
                        const isCampaignSMS = campaign.channel === 'SMS';

                        if (campaign.canCancel) {
                            return [viewDetailsMenuItem, cancelCampaignMenuItem];
                        }
                        if (campaign.canStop && isCampaignSending) {
                            return isCampaignSMS
                                ? [viewDetailsMenuItem, stopCampaignMenuItem]
                                : [
                                      viewDetailsMenuItem,
                                      stopCampaignMenuItem,
                                      finishCampaignMenuItem,
                                      ...(hasCurationPauseFeature ? [pauseCampaignMenuItem] : []),
                                  ];
                        }
                        if (campaign.canStop && isCampaignFinishing) {
                            return [viewDetailsMenuItem, stopCampaignMenuItem];
                        }
                        if (campaign.canDelete) {
                            return isCampaignSMS
                                ? [viewDetailsMenuItem]
                                : [viewDetailsMenuItem, deleteCampaignMenuItem];
                        }

                        return [viewDetailsMenuItem];
                    };
                    const menuItems = getMenuItems();

                    if (!menuItems?.length) return;

                    return <MoreOptionsDropdown buttonTestId={`name-${campaign.name}-options`} menuItems={menuItems} />;
                },
            },
        ],
        [refetch, toggleDeleteModalOpen, history, hasCurationPauseFeature]
    );

    const campaignsWithTimestamp = campaigns?.map((campaign) => ({
        ...campaign,
        scheduledTimestamp: new Date(campaign.startTime),
    }));

    return (
        <>
            {isDeleteError && <ErrorSnackbar errorMessage={errorMessage} />}
            {isUpdateError && <ErrorSnackbar errorMessage={errorMessage} />}
            {infoMessage && <InfoSnackbar successMessage={infoMessage} />}
            {isDeleteSuccess && <SuccessSnackbar successMessage={successMessage} />}
            {isUpdateSuccess && <SuccessSnackbar successMessage={successMessage} />}
            <ConfirmationDialog
                dialogProps={{
                    confirmButtonText: 'Delete',
                    contentText: `You are about to delete "${campaign?.name}." This action cannot be undone.`,
                    title: 'Delete Curation',
                }}
                open={isDeleteModalOpen}
                onConfirm={() => {
                    toggleDeleteModalOpen(null, true);
                    setInfoMessage(`Deleting "${campaign?.name}"`);
                }}
                onModalClose={() => toggleDeleteModalOpen()}
            />
            <Table
                columns={columns}
                data={campaignsWithTimestamp}
                defaultSortingRules={[{ id: 'schedule', desc: true }]}
                isRefetching={isRefetching}
                refetch={refetch}
            />
        </>
    );
};

export default CampaignsTable;
