import { ResponsiveBar } from '@nivo/bar';

const BarGraph = ({ data, bottomLegendLabel, leftLegendLabel }: any) => {
    if (!data) return;

    const getFormattedLabel = (label: any) => {
        const { value } = label;
        if (typeof label.value === 'number') {
            return value.toLocaleString();
        }
        return value;
    };

    return (
        <div style={{ height: '400px', width: '100%' }}>
            <ResponsiveBar
                axisBottom={{
                    legend: bottomLegendLabel,
                    legendOffset: bottomLegendLabel ? 40 : 0,
                    legendPosition: 'middle',
                    tickSize: 0,
                }}
                axisLeft={{
                    tickSize: 0,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: leftLegendLabel,
                    legendPosition: 'middle',
                    legendOffset: -50,
                    truncateTickAt: 0,
                    format: (value) => value.toLocaleString(),
                }}
                borderWidth={1}
                borderColor={{
                    from: 'color',
                    modifiers: [['darker', 0.2]],
                }}
                colors={{ scheme: 'set2' }}
                data={data}
                enableGridY={false}
                isInteractive={false}
                label={(label) => getFormattedLabel(label)}
                margin={{
                    top: 16,
                    right: 16,
                    bottom: bottomLegendLabel ? 60 : 32,
                    left: 70,
                }}
            />
        </div>
    );
};

export default BarGraph;
