import { useCallback, useMemo, useState } from 'react';
import { UseQueryResult } from 'react-query';
import { useHistory } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Link } from '@mui/material';
import { Edit, FileCopy, Visibility } from '@mui/icons-material';
import { LandingPage, Template, isTemplate as isTemplateTypeGuard } from 'domains/content/types';
import IconButton from 'domains/core/components/IconButton';
import Table, { ColumnOption, renderDate } from 'domains/core/components/Table';
import { MutationStatus, URLPaths } from 'models/enums';
import useMutationStatus from 'hooks/useMutationStatus';
import useDeleteLandingPage from 'hooks/mutations/useDeleteLandingPage';
import useDeleteTemplate from 'hooks/mutations/useDeleteTemplate';
import MutationKeys from 'hooks/mutations/keys';
import MoreOptionsDropdown from 'domains/core/components/MoreOptionsDropdown';
import ConfirmationDialog from 'domains/core/components/ConfirmationDialog';
import ErrorSnackbar from 'domains/core/components/Snackbars/ErrorSnackbar';
import SuccessSnackbar from 'domains/core/components/Snackbars/SuccessSnackbar';
import InfoSnackbar from 'domains/core/components/Snackbars/InfoSnackbar';

export type Props<ContentType> = {
    contentQuery: UseQueryResult<ContentType[], unknown>;
};

const getTitleAndIcon = (isLayout: boolean, isTemplate: boolean) => {
    if (isLayout) {
        return {
            title: 'View template',
            Icon: Visibility,
        };
    } else {
        return {
            title: `Edit ${isTemplate ? 'template' : 'landing page'}`,
            Icon: Edit,
        };
    }
};

const ContentTable = <ContentType extends Template | LandingPage>({
    contentQuery: { data: queryData, isLoading: isQueryLoading, isRefetching, refetch },
}: Props<ContentType>) => {
    const isTemplate = isTemplateTypeGuard(queryData[0]);

    const { contentDelete: hasContentDeleteFeature } = useFlags();
    const history = useHistory();

    const [errorMessage, setError] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [content, setContent] = useState<LandingPage | Template>(null);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

    const deleteLandingPageStatus = useMutationStatus(MutationKeys.DELETE_LANDING_PAGE);
    const deleteTemplateStatus = useMutationStatus(MutationKeys.DELETE_TEMPLATE);

    const onError = (error: Error) => setError(error.message);
    const onSuccess = () => {
        setSuccessMessage(`"${content.name}" successfully deleted.`);
        refetch();
    };

    const { isLoading: isDeleteLandingPageLoading, mutate: deleteLandingPage } = useDeleteLandingPage(
        `${content?.id}`,
        {
            onError,
            onSuccess,
        }
    );
    const { isLoading: isDeleteTemplateLoading, mutate: deleteTemplate } = useDeleteTemplate(`${content?.id}`, {
        onError,
        onSuccess,
    });

    const isError = deleteTemplateStatus === MutationStatus.ERROR || deleteLandingPageStatus === MutationStatus.ERROR;
    const isLoading = isDeleteLandingPageLoading || isDeleteTemplateLoading;
    const isSuccess =
        deleteTemplateStatus === MutationStatus.SUCCESS || deleteLandingPageStatus === MutationStatus.SUCCESS;

    const infoMessage = `Deleting "${content?.name}"`;

    const toggleDeleteModalOpen = useCallback(
        (content?: Template | LandingPage, isConfirm?: boolean) => {
            const deleteContent = () => (isTemplate ? deleteTemplate() : deleteLandingPage());

            isConfirm ? deleteContent() : setContent(content);
            setIsDeleteModalOpen(!!content);
        },
        [deleteLandingPage, deleteTemplate, isTemplate]
    );

    const data: object[] = useMemo(
        () =>
            queryData
                ?.map((template: ContentType) => ({
                    dateCreated: new Date(template.createdTimestamp),
                    dateUpdated: new Date(template.lastModifiedTimestamp),
                    ...template,
                }))
                //Filter out templates not compatible with content editor (for both old and new)
                .filter((template: any) => template.beeJson),
        [queryData]
    );
    const columns: ColumnOption[] = useMemo(() => {
        const contentCreatePage = isTemplate ? URLPaths.CONTENT_CREATE_EMAIL : URLPaths.CONTENT_CREATE_PAGE;

        const nameColumn: ColumnOption = {
            Header: 'Name',
            accessor: 'name',
            sortType: 'string',
            align: 'left',
            Cell: (val: any) => (
                <Link href={`${contentCreatePage}?id=${val.row.values.id}`}>{`${
                    val.row.original.isLayout ? 'TEMPLATE - ' : ''
                }${val.cell.value}`}</Link>
            ),
        };
        const dateCreatedColumn: ColumnOption = {
            Header: 'Date Created',
            accessor: 'dateCreated',
            sortType: 'datetime',
            Cell: renderDate,
        };
        const dateUpdatedColumn: ColumnOption = {
            Header: 'Date Updated',
            accessor: 'dateUpdated',
            sortType: 'datetime',
            Cell: renderDate,
        };
        const typeColumn: ColumnOption = {
            Header: 'Type',
            accessor: 'type',
            Cell: () => (isTemplate ? 'email' : 'landing page'),
        };

        const copyColumn: ColumnOption = {
            Header: '',
            accessor: 'copy',
            Cell: (val: any) => (
                <IconButton
                    title={`Copy ${isTemplate ? 'template' : 'landing page'}`}
                    handleClick={() => {
                        // Pass the isCopy flag. If the flag isn't passed, the value for isCopy defaults to false.
                        history.push(`${contentCreatePage}?id=${val.row.values.id}&isCopy=true`);
                    }}
                    Icon={FileCopy}
                />
            ),
            disableSortBy: true,
            align: 'right',
        };

        const editColumn: ColumnOption = {
            Header: '',
            accessor: 'id',
            Cell: (val: any) => (
                <IconButton
                    handleClick={() => history.push(`${contentCreatePage}?id=${val.row.values.id}`)}
                    {...getTitleAndIcon(val.row.original.isLayout, isTemplate)}
                />
            ),
            disableSortBy: true,
            align: 'right',
        };

        const moreOptionsColumn: ColumnOption = {
            Header: '',
            accessor: 'id',
            align: 'right',
            disableSortBy: true,
            Cell: (val: any) => {
                const editOption = {
                    name: 'Edit',
                    onClick: () => {
                        history.push(`${contentCreatePage}?id=${val.row.values.id}`);
                    },
                };
                const copyOption = {
                    name: 'Copy',
                    onClick: () => {
                        history.push(`${contentCreatePage}?id=${val.row.values.id}&isCopy=true`);
                    },
                };
                const deleteOption = {
                    name: 'Delete',
                    onClick: () => toggleDeleteModalOpen(val.row.original),
                };
                const showDeleteOption = !isTemplate || val.row.original.canDelete;
                const menuItems = showDeleteOption ? [editOption, copyOption, deleteOption] : [editOption, copyOption];

                return <MoreOptionsDropdown menuItems={menuItems} />;
            },
        };

        if (hasContentDeleteFeature) {
            return [nameColumn, dateCreatedColumn, dateUpdatedColumn, typeColumn, moreOptionsColumn];
        }

        return [nameColumn, dateCreatedColumn, dateUpdatedColumn, typeColumn, copyColumn, editColumn];
    }, [hasContentDeleteFeature, history, isTemplate, toggleDeleteModalOpen]);

    return (
        <>
            {isError && <ErrorSnackbar errorMessage={errorMessage} />}
            {isLoading && <InfoSnackbar successMessage={infoMessage} />}
            {isSuccess && <SuccessSnackbar successMessage={successMessage} />}
            <ConfirmationDialog
                dialogProps={{
                    confirmButtonText: 'Delete',
                    contentText: `You are about to delete "${content?.name}." This action cannot be undone.`,
                    title: `Delete ${isTemplate ? 'Email' : 'Landing Page'}`,
                }}
                open={isDeleteModalOpen}
                onConfirm={() => toggleDeleteModalOpen(null, true)}
                onModalClose={() => toggleDeleteModalOpen()}
            />
            {!isQueryLoading && (
                <Table
                    data={data}
                    columns={columns}
                    defaultSortingRules={[{ id: 'dateUpdated', desc: true }]}
                    isRefetching={isRefetching}
                    refetch={refetch}
                />
            )}
        </>
    );
};

export default ContentTable;
