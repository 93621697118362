import { UseQueryOptions } from 'react-query';
import useQuery from 'hooks/useQuery';
import QueryKeys from 'hooks/queries/keys';
import { CuredApiPaths } from 'models/enums';

type DashboardOptions = { label: string; value: string }[];

const useDataVisDashboardOptions = (reactQueryOptions?: UseQueryOptions<DashboardOptions, Error>) =>
    useQuery<DashboardOptions>(
        QueryKeys.DATA_VIS_DASHBOARD_OPTIONS,
        CuredApiPaths.DATA_VIS_DASHBOARD_OPTIONS,
        reactQueryOptions
    );

export default useDataVisDashboardOptions;
