import {
    SegmentFilter,
    SegmentFilterEventCondition,
    SegmentFilterEventFields,
    SegmentFilterState,
    isMultiInputOperator as isMultiInputOperatorType,
} from 'domains/segments/types';
import Select from 'domains/core/components/Select';
import { getOperatorValues } from 'domains/segments/pages/SegmentCreatePage/Filter/utils';
import { useFlags } from 'launchdarkly-react-client-sdk';

type Props = {
    condition: SegmentFilterEventCondition;
    conditionIndex: number;
    filterIndex: number;
    filterOptions: SegmentFilter[];
    filterState: SegmentFilterState<SegmentFilterEventFields>;
    updateSegmentFilter: (
        index: number,
        filter: SegmentFilterState<SegmentFilterEventFields>,
        resetPreview?: boolean
    ) => void;
};

const OperatorInput = ({
    condition,
    conditionIndex,
    filterIndex,
    filterOptions,
    filterState,
    updateSegmentFilter,
}: Props) => {
    const { categorySpecificFields } = filterState;
    const { conditions } = categorySpecificFields;
    const { nullOperator: isNullOperatorEnabled } = useFlags();

    const selectedFilterOption = filterOptions.find((filterOption) => filterOption.name === condition.name);

    const filterOperatorsValues = getOperatorValues(selectedFilterOption?.sqlType, isNullOperatorEnabled);

    const handleChangeOperator = (e: any) => {
        const newConditions = conditions.map((condition, index) => {
            if (index === conditionIndex) {
                const isMultiInputOperator = isMultiInputOperatorType(e.target.value);

                condition = {
                    ...(isMultiInputOperator && { autocompleteValue: [] }),
                    autocompleteInputValue: '',
                    name: condition.name,
                    operator: e.target.value,
                    value: isMultiInputOperator ? [] : '',
                };
            }
            return condition;
        });

        updateSegmentFilter(filterIndex, {
            ...filterState,
            categorySpecificFields: {
                ...categorySpecificFields,
                conditions: newConditions,
            },
        });
    };

    return (
        <div>
            <Select
                label="Operator"
                value={condition.operator ?? ''}
                values={filterOperatorsValues}
                style={{ textTransform: 'capitalize', width: '12rem' }}
                onChange={handleChangeOperator}
            />
        </div>
    );
};

export default OperatorInput;
