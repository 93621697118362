import { useMemo } from 'react';
import { Stack, ToggleButton, ToggleButtonGroup, Tooltip } from '@mui/material';
import { SendJobStatus } from 'models/enums';
import Autocomplete from 'domains/core/components/Autocomplete';
import Select, { Value } from 'domains/core/components/Select';
import { Send } from 'domains/campaigns/types';
import {
    EngagementName,
    SegmentFilter,
    SegmentFilterEngagementFields,
    SegmentFilterState,
} from 'domains/segments/types';
import useSendClickableUrls from 'hooks/queries/useSendClickableUrls';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { AdsClick } from '@mui/icons-material';

const engagementOperatorToName: Record<string, EngagementName> = {
    'did receive': EngagementName.RECEIVED_SENDS,
    'did not receive': EngagementName.RECEIVED_SENDS,
    'did click': EngagementName.CLICKED_SENDS,
    'did not click': EngagementName.CLICKED_SENDS,
    'did open': EngagementName.OPENED_SENDS,
    'did not open': EngagementName.OPENED_SENDS,
};

export type Props = {
    filter: SegmentFilterState<SegmentFilterEngagementFields>;
    index: number;
    engagementFilters: SegmentFilter[];
    sends: Send[];
    sx?: {
        autocomplete: object;
    };
    updateSegmentFilter: (
        index: number,
        filter: SegmentFilterState<SegmentFilterEngagementFields>,
        resetPreview?: boolean
    ) => void;
};

const ActivityFilter = ({ filter, index, engagementFilters, sends, sx, updateSegmentFilter }: Props) => {
    const { linkSegmentation: hasLinkSegmentationFeature } = useFlags();
    const { autocompleteInputValue, autocompleteValue, operator, categorySpecificFields } = filter;
    const { segmentLinkInput } = categorySpecificFields;

    let segmentLinkAutocompleteValue, segmentLinkAutocompleteInputValue, isSelectLinkInputOpen;
    if (segmentLinkInput) {
        isSelectLinkInputOpen = segmentLinkInput.value !== null;
        ({
            autocompleteValue: segmentLinkAutocompleteValue,
            autocompleteInputValue: segmentLinkAutocompleteInputValue,
        } = segmentLinkInput);
    }
    const isClickedSends = engagementOperatorToName[operator] === EngagementName.CLICKED_SENDS;

    const selectedSendvalue =
        autocompleteValue && (Array.isArray(autocompleteValue) ? autocompleteValue[0].value : autocompleteValue.value);
    const sendId = Number(selectedSendvalue) || undefined;

    const { data: sendClickableUrls } = useSendClickableUrls({
        sendId,
        reactQueryOptions: { enabled: hasLinkSegmentationFeature && isClickedSends && !!sendId },
    });

    const engagementOperators: Value[] = useMemo(() => {
        if (!engagementFilters) {
            return [];
        }
        return engagementFilters.reduce(
            (values, filter) => [
                ...values,
                {
                    label: `did ${filter.displayName}`,
                    value: `did ${filter.displayName}`,
                },
                {
                    label: `did not ${filter.displayName}`,
                    value: `did not ${filter.displayName}`,
                },
            ],
            [] as Value[]
        );
    }, [engagementFilters]);

    const campaignDropdownOptions = useMemo(
        () =>
            sends
                ?.filter((c) => c.status !== 'FAILED' && c.status !== SendJobStatus.CANCELED)
                .map((c) => ({ label: c.name, value: c.id })) ?? [],
        [sends]
    );

    const clickableUrlOptions = sendClickableUrls?.map((url) => ({
        label: url,
        value: url,
    }));

    const handleOperatorChange = (e: any) => {
        updateSegmentFilter(index, {
            ...filter,
            name: engagementOperatorToName[e.target.value],
            operator: e.target.value,
            categorySpecificFields: {
                ...categorySpecificFields,
                value: null,
                segmentLinkInput: {
                    value: null,
                    autocompleteInputValue: '',
                    autocompleteValue: null,
                },
            },
            autocompleteInputValue: '',
            autocompleteValue: null,
        });
    };

    const handleChangeAutocompleteInputValueForSegmentLink = (newInputValue: string) => {
        updateSegmentFilter(index, {
            ...filter,
            categorySpecificFields: {
                ...categorySpecificFields,
                segmentLinkInput: {
                    ...segmentLinkInput,
                    autocompleteInputValue: newInputValue,
                },
            },
        });
    };

    const handleChangeAutocompleteValueForSegmentLink = (value: any) => {
        updateSegmentFilter(index, {
            ...filter,
            categorySpecificFields: {
                ...categorySpecificFields,
                segmentLinkInput: {
                    ...segmentLinkInput,
                    value: value?.value,
                    autocompleteValue: value,
                },
            },
        });
    };

    const handleToggleButtonClick = () => {
        updateSegmentFilter(index, {
            ...filter,
            categorySpecificFields: {
                ...categorySpecificFields,
                segmentLinkInput: {
                    autocompleteInputValue: '',
                    autocompleteValue: null,
                    value: '',
                },
            },
        });
    };

    const handleChangeAutocompleteInputValueForSends = (newInputValue: string) => {
        updateSegmentFilter(index, {
            ...filter,
            autocompleteInputValue: newInputValue,
        });
    };

    const handleChangeAutocompleteValueForSends = (value: any) => {
        updateSegmentFilter(index, {
            ...filter,
            autocompleteValue: value,
            categorySpecificFields: {
                value: value?.value,
                segmentLinkInput: {
                    autocompleteInputValue: '',
                    autocompleteValue: null,
                    value: null,
                },
            },
        });
    };

    return (
        <>
            <Stack direction="row" order="1">
                <Select
                    label="Operator"
                    data-test="activity-operator-select"
                    data-testid="activity-operator-select"
                    sx={{ textTransform: 'capitalize', width: '12rem' }}
                    value={operator ?? ''}
                    values={engagementOperators}
                    onChange={(e: any) => {
                        handleOperatorChange(e);
                    }}
                />
            </Stack>
            {operator && (
                <Stack direction="row" order="2">
                    <Autocomplete
                        data-testid="send-autocomplete"
                        data-test="send-autocomplete"
                        inputValue={autocompleteInputValue ?? ''}
                        isOptionEqualToValue={(option, value) => option.value === value.value}
                        label="Value"
                        onChange={(e: any, value) => {
                            handleChangeAutocompleteValueForSends(value);
                        }}
                        onInputChange={(e, newInputValue) => handleChangeAutocompleteInputValueForSends(newInputValue)}
                        options={campaignDropdownOptions}
                        placeholder="Enter a value for sends"
                        sx={{ width: '33rem', ...sx?.autocomplete }}
                        // Setting undefined when there is no value will make the component uncontrolled, so we need to set default values for value and inputValue.
                        type="string"
                        value={autocompleteValue ?? null}
                    />
                </Stack>
            )}
            {hasLinkSegmentationFeature && isClickedSends && (
                <Stack direction="row" order="3" alignItems="center">
                    {!isSelectLinkInputOpen ? (
                        <>
                            {autocompleteValue && (
                                <ToggleButtonGroup
                                    data-testid="select-link-toggle-button-group"
                                    data-test="select-link-toggle-button-group"
                                    value="select link"
                                    exclusive
                                    aria-label="match type"
                                    style={{ width: '42px', height: '42px' }}
                                >
                                    <ToggleButton
                                        data-testid="select-link-toggle-button"
                                        data-test="select-link-toggle-button"
                                        value="select-link"
                                        style={{ width: '42px', height: '42px' }}
                                        onClick={handleToggleButtonClick}
                                    >
                                        <Tooltip aria-label="select link" title="Select Link">
                                            <AdsClick />
                                        </Tooltip>
                                    </ToggleButton>
                                </ToggleButtonGroup>
                            )}
                        </>
                    ) : (
                        <Autocomplete
                            data-testid="select-link-autocomplete"
                            data-test="select-link-autocomplete"
                            inputValue={segmentLinkAutocompleteInputValue ?? ''}
                            isOptionEqualToValue={(option, value) => option.value === value.value}
                            label="Select Link"
                            onChange={(e: any, value) => {
                                handleChangeAutocompleteValueForSegmentLink(value);
                            }}
                            onInputChange={(e, newInputValue) => {
                                handleChangeAutocompleteInputValueForSegmentLink(newInputValue);
                            }}
                            options={clickableUrlOptions ?? []}
                            placeholder="Enter link"
                            sx={{ width: '33rem', ...sx?.autocomplete }}
                            // Setting undefined when there is no value will make the component uncontrolled, so we need to set default values for value and inputValue.
                            type="string"
                            value={segmentLinkAutocompleteValue ?? null}
                        />
                    )}
                </Stack>
            )}
        </>
    );
};

export default ActivityFilter;
