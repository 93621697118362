import {
    SegmentFilter,
    SegmentFilterEventCondition,
    SegmentFilterEventFields,
    SegmentFilterState,
} from 'domains/segments/types';
import Select from 'domains/core/components/Select';

type Props = {
    condition: SegmentFilterEventCondition;
    conditionIndex: number;
    filterIndex: number;
    filterOptions: SegmentFilter[];
    filterState: SegmentFilterState<SegmentFilterEventFields>;
    updateSegmentFilter: (
        index: number,
        filter: SegmentFilterState<SegmentFilterEventFields>,
        resetPreview?: boolean
    ) => void;
};

const FilterInput = ({
    condition,
    conditionIndex,
    filterIndex,
    filterOptions,
    filterState,
    updateSegmentFilter,
}: Props) => {
    const { categorySpecificFields } = filterState;
    const { conditions } = categorySpecificFields;

    const filterOptionsValues = filterOptions.map((filterOption) => ({
        label: filterOption.displayName,
        value: filterOption.name,
    }));

    const handleChangeFilter = (e: any) => {
        const newConditions = conditions.map((condition, index) => {
            if (index === conditionIndex) {
                condition = {
                    name: e.target.value,
                    operator: null as any,
                    value: '',
                };
            }
            return condition;
        });

        updateSegmentFilter(filterIndex, {
            ...filterState,
            categorySpecificFields: {
                ...categorySpecificFields,
                conditions: newConditions,
            },
        });
    };

    return (
        <div>
            <Select
                label="Filter"
                value={condition.name}
                values={filterOptionsValues}
                style={{ textTransform: 'capitalize', width: '12rem' }}
                onChange={handleChangeFilter}
            />
        </div>
    );
};

export default FilterInput;
