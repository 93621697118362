import { CampaignType, FilterCategory } from 'models/enums';
import { actionTypes } from './customCampaignActions';
import { CampaignChannels, EmailSendInputState, SendRecurrenceFrequency, SplitTypes } from 'domains/campaigns/types';
import {
    EngagementAPIOperator,
    EngagementUIOperator,
    SegmentFilterCategorySpecificField,
    SegmentFilterState,
} from 'domains/segments/types';
import { engagementUiToApiOperator } from 'domains/segments/utils';

export type StateTypes = {
    name: string;
    type: CampaignType;
    sends: EmailSendInputState[];
};

export const initialState: StateTypes = {
    name: '',
    type: CampaignType.FLEXIBLE,
    sends: [
        {
            channel: CampaignChannels.EMAIL,
            name: '',
            preHeader: '',
            recurrenceFrequency: SendRecurrenceFrequency.DOES_NOT_REPEAT,
            scheduledEndTimestamp: null,
            scheduledTimestamp: null,
            segmentId: null,
            senderProfileId: null,
            subjectLine: '',
            templateId: null,
        },
    ],
};

const additionalSend: EmailSendInputState = {
    channel: CampaignChannels.EMAIL,
    name: '',
    parentIndex: null,
    preHeader: '',
    scheduledTimestamp: null,
    senderProfileId: null,
    subjectLine: '',
    templateId: null,
};

function getUpdatedSends(sends: StateTypes['sends'], activeSendIndex: number, updater: any) {
    return sends.map((send, index) => {
        if (index === activeSendIndex) return { ...send, ...updater };
        return send;
    });
}

function getUpdatedSendsWithSegments(
    sends: StateTypes['sends'],
    meetsCriteriaSegment: SegmentFilterState<SegmentFilterCategorySpecificField>,
    activeSendIndex: number
) {
    const { category } = meetsCriteriaSegment;

    if (category === FilterCategory.CONTACT_ACTIVITY) {
        const sendsWithMeetsCriteriaSegments = getUpdatedSends(sends, activeSendIndex, {
            segments: [meetsCriteriaSegment],
        });

        const doesNotMeetCriteriaOperator = Object.values(EngagementAPIOperator).filter(
            (operator) =>
                operator !==
                engagementUiToApiOperator[(meetsCriteriaSegment.operator as unknown) as EngagementUIOperator]
        )[0];
        const doesNotMeetCriteriaSegment = {
            ...meetsCriteriaSegment,
            operator: doesNotMeetCriteriaOperator,
        };

        const sendWithSegments = getUpdatedSends(sendsWithMeetsCriteriaSegments, activeSendIndex + 1, {
            segments: [doesNotMeetCriteriaSegment],
        });

        return sendWithSegments;
    }

    if (category === FilterCategory.CONTACT_DETAILS) {
        const sendsWithMeetsCriteriaSegments = getUpdatedSends(sends, activeSendIndex, {
            segments: [meetsCriteriaSegment],
        });

        // While the campaign is being built out, the segments for meets and
        // does not meet criteria will be the same. We will tell the backend
        // to negate the query associated with the does not meet criteria segment
        // upon campaign publish.
        const doesNotMeetCriteriaSegment = meetsCriteriaSegment;

        const sendWithSegments = getUpdatedSends(sendsWithMeetsCriteriaSegments, activeSendIndex + 1, {
            segments: [doesNotMeetCriteriaSegment],
        });

        return sendWithSegments;
    }
}

export function customCurationReducer(state: StateTypes, action: any) {
    switch (action.type) {
        case actionTypes.SET_NAME: {
            return {
                ...state,
                name: action.name,
            };
        }
        case actionTypes.SELECT_AUDIENCE: {
            return {
                ...state,
                sends: [
                    {
                        ...state.sends[0],
                        segmentId: action.selectedAudienceId,
                    },
                    ...state.sends.slice(1),
                ],
            };
        }
        case actionTypes.SELECT_CONTENT: {
            const { templateId, sendIndex } = action.parameters;
            const updatedSends = getUpdatedSends(state.sends, sendIndex, { templateId });

            return { ...state, sends: updatedSends };
        }
        case actionTypes.SET_SENDER_PROFILE_ID: {
            const { sendIndex, senderProfileId } = action.parameters;
            const updatedSends = getUpdatedSends(state.sends, sendIndex, { senderProfileId });

            return { ...state, sends: updatedSends };
        }
        case actionTypes.SET_SUBJECT_LINE: {
            const { sendIndex, subjectLine } = action.parameters;
            const updatedSends = getUpdatedSends(state.sends, sendIndex, { subjectLine });

            return { ...state, sends: updatedSends };
        }
        case actionTypes.SET_PRE_HEADER: {
            const { preHeader, sendIndex } = action.parameters;
            const updatedSends = getUpdatedSends(state.sends, sendIndex, { preHeader });

            return { ...state, sends: updatedSends };
        }
        case actionTypes.SET_SCHEDULED_TIMESTAMP: {
            const { scheduledTimestamp, sendIndex } = action.parameters;
            const updatedSends = getUpdatedSends(state.sends, sendIndex, { scheduledTimestamp });

            return { ...state, sends: updatedSends };
        }
        case actionTypes.SET_HAS_RECOMMENDED_TIME: {
            const { hasRecommendedTime } = action.parameters;
            const updatedSends = getUpdatedSends(state.sends, 0, { hasRecommendedTime });

            return { ...state, sends: updatedSends };
        }
        case actionTypes.SET_RECURRENCE_FREQUENCY: {
            const { recurrenceFrequency } = action.parameters;
            const updatedSends = getUpdatedSends(state.sends, 0, { recurrenceFrequency });

            return { ...state, sends: updatedSends };
        }
        case actionTypes.SET_SCHEDULED_END_TIMESTAMP: {
            const { scheduledEndTimestamp } = action.parameters;
            const updatedSends = getUpdatedSends(state.sends, 0, { scheduledEndTimestamp });

            return { ...state, sends: updatedSends };
        }
        case actionTypes.SET_PARENT_AUDIENCE_DELAY_INTERVAL: {
            const { parentAudienceDelayInterval, sendIndex } = action.parameters;
            const updatedSends = getUpdatedSends(state.sends, sendIndex, { parentAudienceDelayInterval });

            return { ...state, sends: updatedSends };
        }
        case actionTypes.ADD_CONDITIONAL_SPLIT_SENDS: {
            const { sendIndex } = action.parameters;
            const updatedSends = getUpdatedSends(state.sends, sendIndex, { splitType: SplitTypes.MEETS });

            const meetsCriteriaSend = state.sends[sendIndex];

            const doesNotMeetCriteriaSend = {
                ...meetsCriteriaSend,
                splitType: SplitTypes.DOES_NOT_MEET,
            };

            const sendsWithConditionalSplit = updatedSends.concat(doesNotMeetCriteriaSend);
            return { ...state, sends: sendsWithConditionalSplit };
        }
        case actionTypes.SET_SEGMENT: {
            const { segment: meetsCriteriaSegment, sendIndex } = action.parameters;

            const sendsWithSegments = getUpdatedSendsWithSegments(state.sends, meetsCriteriaSegment, sendIndex);

            return { ...state, sends: sendsWithSegments };
        }
        case actionTypes.SET_SPLIT_ACTION: {
            const { splitAction, sendIndex } = action.parameters;
            const updatedSends = getUpdatedSends(state.sends, sendIndex, { splitAction });

            return { ...state, sends: updatedSends };
        }
        case actionTypes.ADD_SEND: {
            const addedSend = [
                {
                    ...additionalSend,
                    parentIndex: action.parameters.parentIndex,
                },
            ];

            const updatedSends = state.sends.concat(addedSend);
            return { ...state, sends: updatedSends };
        }
        case actionTypes.REMOVE_SEND: {
            const updatedSends = state.sends.slice(0, -1);
            return { ...state, sends: updatedSends };
        }
        case actionTypes.RESET_CONDITIONAL_SPLIT_SEND: {
            const { sendIndex } = action.parameters;
            const updatedSends = getUpdatedSends(state.sends, sendIndex, {
                scheduledTimestamp: null,
                senderProfileId: null,
                subjectLine: '',
                templateId: null,
            });

            return { ...state, sends: updatedSends };
        }
    }

    throw Error('Unknown action: ' + action.type);
}
