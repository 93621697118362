import TextField from 'domains/core/components/TextField';
import { SegmentDemographicValue } from 'domains/segments/types';

type Props = {
    placeholderName: string;
    value: SegmentDemographicValue;
    onChange: (value: any) => void;
};

const PercentageInput = ({ placeholderName, value, onChange }: Props) => (
    <TextField
        data-testid="valueTextField"
        label="Value"
        width="16rem"
        placeholder={`Enter a percentile value for ${placeholderName}`}
        type="number"
        value={value}
        onChange={(e: any) => onChange(e.target.value)}
    />
);

export default PercentageInput;
