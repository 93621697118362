import { useMemo } from 'react';
import { Box, Chip, Skeleton, Stack, Tooltip, Typography } from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
import { getNumberWithCommas } from 'utils';
import { Segment } from 'domains/segments/types';
import { hasInvalidRelativeDates } from 'domains/segments/utils';
import { CampaignChannels } from 'domains/campaigns/types';
import { InfoOutlined } from '@mui/icons-material';
import { RecordType } from 'models/enums';
import { greyChipStyles } from 'domains/core/components/Chip/Chip';
import useAudienceCount, { UseAudienceCountResponse } from 'hooks/queries/useAudienceCount';

const chipStyles = { ...greyChipStyles, textTransform: 'capitalize' };

type AudienceCountProps = {
    data: UseAudienceCountResponse;
    isError: boolean;
    isLoading: boolean;
};

const AudienceCount = ({ data, isError, isLoading }: AudienceCountProps) => (
    <Stack direction="row" justifyContent="flex-start">
        {isError && <WarningIcon sx={{ color: 'warning.main', mr: 1 }} />}
        <Typography variant="body2" width="100%">
            {isError && 'Count not available'}
            {isLoading && <Skeleton data-testid="segment-count-skeleton" />}
            {!isError && !isLoading && (
                <>
                    <span data-test="segment-count">{getNumberWithCommas(data.count)}</span>
                    {` ${data.count === 1 ? 'Contact' : 'Contacts'}`}
                </>
            )}
        </Typography>
    </Stack>
);

type AudienceCountForChannelProps = {
    data: UseAudienceCountResponse;
    isError: boolean;
    isLoading: boolean;
    channel: CampaignChannels;
};

const AudienceCountForChannel = ({ data, isError, isLoading, channel }: AudienceCountForChannelProps) => {
    const emailChannel = channel === CampaignChannels.EMAIL;

    return (
        <Stack direction="row" justifyContent="flex-start">
            {isError && <WarningIcon sx={{ color: 'warning.main', mr: 1 }} />}
            <Typography variant="body2" width="100%">
                {isError && emailChannel && 'Email Count not available'}
                {isLoading && <Skeleton data-testid="segment-count-skeleton" />}
                {!isError && !isLoading && emailChannel && (
                    <Stack direction="row" alignItems="center" columnGap={0.5}>
                        <span data-test="email-segment-count">{getNumberWithCommas(data.count)}</span>
                        {`Email ${data.count === 1 ? 'Contact' : 'Contacts'}`}
                        <Tooltip
                            title="This count filters out unsubscribed and invalid email addresses"
                            sx={{
                                // Setting pointerEvents: 'auto' allows the Tooltip to show on hover if it's used in the label.
                                pointerEvents: 'auto',
                            }}
                        >
                            <Stack sx={{ fontSize: 14, marginTop: 0.5 }}>{<InfoOutlined fontSize="inherit" />}</Stack>
                        </Tooltip>
                    </Stack>
                )}
            </Typography>
        </Stack>
    );
};

type Props = {
    segment: Segment;
};

const AudienceDetails = ({ segment }: Props) => {
    const { id, segmentDefinition } = segment;

    const { data, isError, isLoading } = useAudienceCount(id.toString(), undefined, {
        enabled: !hasInvalidRelativeDates(segment),
    });
    const { data: emailCountData, isError: emailCountIsError, isLoading: emailCountIsLoading } = useAudienceCount(
        segment.id.toString(),
        CampaignChannels.EMAIL,
        {
            enabled: !hasInvalidRelativeDates(segment),
        }
    );

    const recordTypeChips = useMemo(() => {
        const contactRecordType = segmentDefinition.contactRecordType;

        if (
            !contactRecordType ||
            contactRecordType.length === 0 ||
            contactRecordType.includes(RecordType.ALL_CONTACTS)
        ) {
            return <Chip label={RecordType.ALL_CONTACTS} sx={chipStyles} />;
        }

        return (
            <Stack direction="row" flexWrap="wrap" spacing={1}>
                {contactRecordType.map((recordType, idx) => (
                    <Chip key={recordType + idx} label={recordType} sx={chipStyles} />
                ))}
            </Stack>
        );
    }, [segmentDefinition.contactRecordType]);

    return (
        <>
            <Typography>Audience Details</Typography>
            <AudienceCount data={data} isError={!isLoading && (!data || isError)} isLoading={isLoading} />
            <AudienceCountForChannel
                data={emailCountData}
                isError={!emailCountIsLoading && (!emailCountData || emailCountIsError)}
                isLoading={emailCountIsLoading}
                channel={CampaignChannels.EMAIL}
            />
            <Box mt={1}>{recordTypeChips}</Box>
        </>
    );
};

export default AudienceDetails;
