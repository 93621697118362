import { Box } from '@mui/material';
import { SegmentDefinition, SegmentDemographic } from 'domains/segments/types';
import FilterDetails from '../FilterDetails';
import MatchTypeChip from '../MatchTypeChip';

type Props = {
    details: SegmentDemographic[];
    matchType: SegmentDefinition['matchType'];
};

const DemographicDetails = ({ details, matchType }: Props) => (
    <>
        {details.map(({ name, operator, sqlType, value }: SegmentDemographic, index) => (
            <Box data-testid="filter" key={index} textAlign="right">
                {index !== 0 && <MatchTypeChip matchType={matchType} />}
                <FilterDetails name={name} operator={operator} sqlType={sqlType} value={value} />
            </Box>
        ))}
    </>
);

export default DemographicDetails;
