import { useAuth0 } from '@auth0/auth0-react';
import useConfig from 'hooks/useConfig';

export type LDUser = {
    key: string;
    email: string;
    custom: {
        role: string;
    };
};

/**
 * Gets the LD user used for targeting.
 * @returns The LD user or undefined if the user is not authenticated
 */
const useLDUser = (): LDUser | undefined => {
    const { isAuthenticated, user } = useAuth0();
    const {
        config: { AUTH0_AUDIENCE_ENDPOINT },
    } = useConfig();

    if (isAuthenticated) {
        const { email } = user;
        const roles = user[AUTH0_AUDIENCE_ENDPOINT];
        const role: string = roles[0];
        const ldUser: LDUser = { key: email, email, custom: { role } };
        return ldUser;
    }
};

export default useLDUser;
