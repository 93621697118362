import { Typography } from '@mui/material';
import Card from '../Card';
import useContactCount from './useContactCount';

const ContactsCard = () => {
    const { data, isError, isLoading } = useContactCount();

    const renderCount = () => {
        if (isLoading) return 'Loading...';
        if (isError) return 'Something went wrong.';
        return (
            <Typography variant="body1" fontWeight="bold">
                {isLoading ? 'Loading...' : data?.count?.toLocaleString()}
            </Typography>
        );
    };

    return (
        <Card sx={{ alignContent: 'center' }}>
            <Typography variant="body1" fontWeight="bold">
                {renderCount()}
            </Typography>
            Contacts
        </Card>
    );
};

export default ContactsCard;
