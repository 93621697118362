import { CallSplit } from '@mui/icons-material';
import { Stack } from '@mui/material';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useEffect, useMemo, useRef, useState } from 'react';
import FormLayout from 'domains/core/components/FormLayout';
import Select, { getOptionValues, Value } from 'domains/core/components/Select';
import { FilterCategory, RecordType } from 'models/enums';
import ActivityFilter from 'domains/segments/pages/SegmentCreatePage/Filter/ActivityFilter';
import DemographicFilter from 'domains/segments/pages/SegmentCreatePage/Filter/DemographicFilter';
import {
    SegmentFilterCategorySpecificField,
    SegmentFilterEngagementFields,
    SegmentFilterState,
} from 'domains/segments/types';
import { getDemographicFilters } from 'domains/segments/utils';
import useSegmentFilters from 'hooks/queries/useSegmentFilters';
import useSends from 'hooks/queries/useSends';
import { StateTypes } from '../../../customCampaignReducer';
import { setSegment } from '../../../customCampaignActions';
import { CustomCampaignSteps } from '../../../CustomCampaign';

const initialFilterState: SegmentFilterState<SegmentFilterCategorySpecificField> = {
    id: 0,
    category: FilterCategory.CONTACT_ACTIVITY,
    name: '',
    operator: null,
    categorySpecificFields: {
        value: null,
    },
    autocompleteValue: null,
    autocompleteInputValue: '',
};

type Props = {
    activeSendIndex: number;
    campaignState: StateTypes;
    dispatchCampaignAction: React.Dispatch<any>;
    setIsContinueEnabled: (isContinueEnabled: boolean) => void;
    setNextStep: (step: CustomCampaignSteps) => void;
};

const CustomCampaignSplitCriteriaStep = ({
    activeSendIndex,
    campaignState,
    dispatchCampaignAction,
    setIsContinueEnabled,
    setNextStep,
}: Props) => {
    const initialCategory =
        campaignState.sends[activeSendIndex]?.segments?.[0]?.category || FilterCategory.CONTACT_ACTIVITY;
    const [category, setCategory] = useState(initialCategory);
    const [filter, setFilter] = useState(initialFilterState);
    const [datetimeErrors, setDatetimeErrors] = useState({});
    const valuesRef = useRef('');

    const { flexibleCurationsContactDetails: hasContactDetailsOption } = useFlags();

    const getIsCategoryDisabled = (category: FilterCategory): boolean => {
        if (hasContactDetailsOption) {
            return category === FilterCategory.CONTACT_EVENTS;
        }
        return category !== FilterCategory.CONTACT_ACTIVITY;
    };

    const filterCategories: Value[] = Object.values(FilterCategory).map((category) => ({
        label: category,
        value: category,
        disabled: getIsCategoryDisabled(category),
    }));

    const { data: segmentFilters } = useSegmentFilters({
        version: 2,
    });

    const { data: sends } = useSends({
        path: valuesRef.current,
        reactQueryOptions: { enabled: Boolean(valuesRef.current) },
    });

    useEffect(() => {
        const segments = campaignState.sends[activeSendIndex]?.segments;
        // @ts-ignore
        if (segments) setFilter(segments[0]);

        setNextStep(CustomCampaignSteps.SELECT_PATH_ACTION);
        // The dependency array only contains `activeSendIndex` because we only want it to run
        // if the component rerenders when we're switching from one split to another in the nav.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeSendIndex]);

    useEffect(() => {
        const isValidEventSegment = filter?.category === FilterCategory.CONTACT_ACTIVITY && !!filter?.operator;

        const hasDatetimeError = Object.values(datetimeErrors).includes(true);
        const isValidDemographicSegment =
            filter?.category === FilterCategory.CONTACT_DETAILS &&
            !!filter?.operator &&
            (!!filter?.autocompleteInputValue.length || !!filter?.autocompleteValue) &&
            !hasDatetimeError;

        setIsContinueEnabled(isValidEventSegment || isValidDemographicSegment);
    }, [filter, setIsContinueEnabled, datetimeErrors]);

    const engagementFilters = useMemo(() => {
        if (!segmentFilters) {
            return [];
        }
        // RecordTypes[0] represents engagement filters because there's a single item and its recordType value is "" from the API
        return segmentFilters.engagement.recordTypes[0].filters;
    }, [segmentFilters]);

    const engagementNameToValuesRef = useMemo(() => {
        if (!engagementFilters) return {};

        return engagementFilters.reduce((previousValue: Record<string, string>, currentValue) => {
            previousValue[currentValue.name] = currentValue.valuesRef;
            return previousValue;
        }, {});
    }, [engagementFilters]);

    const allContactsRecordType = {
        label: RecordType.ALL_CONTACTS,
        value: RecordType.ALL_CONTACTS,
    };
    const recordTypes = getOptionValues([allContactsRecordType]);
    const segmentRecordTypes = segmentFilters?.demographic?.recordTypes;
    const demographicFilters = useMemo(() => {
        if (!segmentFilters) {
            return [];
        }
        const result = getDemographicFilters({ hasAllContacts: true, recordTypes, segmentRecordTypes });

        return result;
    }, [segmentFilters, recordTypes, segmentRecordTypes]);

    const handleCategorySelection = (e: any) => {
        setCategory(e.target.value);
        setFilter({
            ...initialFilterState,
            category: e.target.value,
        });
    };

    const handleDatetimeError = (filterId: string, error: boolean) =>
        setDatetimeErrors({ ...datetimeErrors, [filterId]: error });

    return (
        <FormLayout
            header="select split criteria"
            stepName="conditional split"
            icon={<CallSplit />}
            guidingText="Create a split in your Curation based on audience behavior."
        >
            <Stack alignItems="flex-start" columnGap={2} direction="row" flexWrap="wrap" rowGap={2}>
                <Select
                    label="Filter type"
                    sx={{ textTransform: 'capitalize', width: '12rem' }}
                    value={category}
                    values={filterCategories}
                    onChange={handleCategorySelection}
                />
                {category === FilterCategory.CONTACT_ACTIVITY && engagementFilters && (
                    <ActivityFilter
                        filter={filter as SegmentFilterState<SegmentFilterEngagementFields>}
                        index={0}
                        engagementFilters={engagementFilters}
                        sends={sends}
                        sx={{ autocomplete: { width: '300px' } }}
                        updateSegmentFilter={(index, filter) => {
                            setFilter(filter);
                            valuesRef.current = engagementNameToValuesRef[filter.name];
                            dispatchCampaignAction(setSegment(filter, activeSendIndex));
                        }}
                    />
                )}
                {category === FilterCategory.CONTACT_DETAILS && demographicFilters && (
                    <DemographicFilter
                        filter={filter}
                        index={0}
                        filterOptions={demographicFilters}
                        handleDatetimeError={handleDatetimeError}
                        updateSegmentFilter={(index, filter) => {
                            setFilter(filter);
                            dispatchCampaignAction(setSegment(filter, activeSendIndex));
                        }}
                    />
                )}
            </Stack>
        </FormLayout>
    );
};

export default CustomCampaignSplitCriteriaStep;
