import { SelectChangeEvent, Stack, Typography } from '@mui/material';
import InputLabel from 'domains/core/components/InputLabel';
import Select from 'domains/core/components/Select';
import TextField from 'domains/core/components/TextField';
import { getRelativeDateRangeOffset } from 'domains/segments/pages/SegmentCreatePage/Filter/utils';
import { RelativeDateRangeDirection, RelativeDateRangeValueAndUnit } from 'domains/segments/types';
import { useState } from 'react';
import { RelativeDateRangeArgs } from '../FilterValueInput';

export type Props = {
    direction: RelativeDateRangeDirection;
    duration: number;
    handleRelativeDateRangeChange: ({ relativeDateRange, unit }: RelativeDateRangeArgs) => void;
    isDateTime?: boolean;
    setDirection: (direction: RelativeDateRangeDirection) => void;
    timeframe: RelativeDateRangeValueAndUnit['unit'];
};

const RelativeDateRange = ({
    direction,
    duration,
    handleRelativeDateRangeChange,
    isDateTime,
    setDirection,
    timeframe,
}: Props) => {
    const [isDaysFocused, setIsDaysFocused] = useState(false);

    const handleTimeframeChange = (timeframe: RelativeDateRangeValueAndUnit['unit']) => {
        handleRelativeDateRangeChange({ unit: timeframe });
    };

    const renderTimeframePicker = () => {
        if (isDateTime) {
            return (
                <Stack direction="row" columnGap={2}>
                    <TextField
                        type="number"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            const duration = e.target.value === '' ? '' : Number(e.target.value);
                            handleRelativeDateRangeChange({
                                relativeDateRange: {
                                    duration: {
                                        value: duration === '' ? null : duration,
                                    },
                                    offset: getRelativeDateRangeOffset(direction, duration === '' ? null : duration),
                                },
                                unit: timeframe as RelativeDateRangeValueAndUnit['unit'],
                            });
                        }}
                        value={duration ?? ''}
                        width="5.625rem"
                    />
                    <Select
                        label="Timeframe"
                        sx={{ width: '12rem' }}
                        value={timeframe ?? ''}
                        values={[
                            {
                                label: (
                                    <InputLabel
                                        label="Days"
                                        labelIconTooltipText="Each day counted as a 24-hour time period relative to the current date and time."
                                    />
                                ),
                                value: 'day',
                            },
                            { label: 'Hours', value: 'hour' },
                        ]}
                        onChange={(e: any) => {
                            handleTimeframeChange(e.target.value);
                        }}
                    />
                </Stack>
            );
        }

        return (
            <TextField
                label="Days"
                type="number"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const duration = e.target.value === '' ? '' : Number(e.target.value);
                    handleRelativeDateRangeChange({
                        relativeDateRange: {
                            duration: {
                                value: duration === '' ? null : duration,
                            },
                            offset: getRelativeDateRangeOffset(direction, duration === '' ? null : duration),
                        },
                        unit: 'day',
                    });
                }}
                onBlur={() => setIsDaysFocused(false)}
                onFocus={() => setIsDaysFocused(true)}
                value={duration ?? ''}
                trailingIcon={
                    (isDaysFocused || Boolean(duration) || duration === 0) && (
                        <Typography data-testid="trailing icon" variant="inputText">
                            days
                        </Typography>
                    )
                }
                width="8rem"
            />
        );
    };

    return (
        <Stack direction="row" columnGap={2}>
            <Select
                label="Relative date range"
                value={direction ?? ''}
                values={[
                    { label: 'the last', value: 'the last' },
                    { label: 'the next', value: 'the next' },
                ]}
                onChange={(e: SelectChangeEvent<unknown>) => {
                    const value = e.target.value as RelativeDateRangeDirection;
                    setDirection(value);
                    handleRelativeDateRangeChange({
                        relativeDateRange: {
                            offset: getRelativeDateRangeOffset(value, duration),
                        },
                    });
                }}
                sx={{ textTransform: 'capitalize', width: '16rem' }}
            />
            {renderTimeframePicker()}
        </Stack>
    );
};

export default RelativeDateRange;
