import { AutocompleteOption } from 'domains/core/components/Autocomplete/Autocomplete';
import { FilterCategory, SQLType } from 'models/enums';

// Operators
export enum BooleanOperator {
    IS_EQUAL_TO = 'is equal to',
    IS_NOT_EQUAL_TO = 'is not equal to',
}
export enum DateOperator {
    ON = 'on',
    // deprecated - users can no longer create a filter with this operator, but there are old filters containing this operator.
    BEFORE_OR_ON = 'before or on',
    // deprecated - users can no longer create a filter with this operator, but there are old filters containing this operator.
    AFTER_OR_ON = 'after or on',
}
export enum DateRangeOperator {
    WITHIN = 'within',
    NOT_WITHIN = 'not within',
}
export enum TimestampOperator {
    BEFORE = 'before',
    AFTER = 'after',
}
export enum NumericOperator {
    IS_GREATER_THAN = 'is greater than',
    IS_GREATER_THAN_OR_EQUAL_TO = 'is greater than or equal to',
    IS_LESS_THAN = 'is less than',
    IS_LESS_THAN_OR_EQUAL_TO = 'is less than or equal to',
}
export enum PercentileOperator {
    IS_AT_OR_ABOVE_PERCENTILE = 'is at or above percentile',
    IS_AT_OR_BELOW_PERCENTILE = 'is at or below percentile',
}
export enum InclusionAPIOperator {
    IN = 'in',
    NOT_IN = 'not in',
}
export enum InclusionUIOperator {
    IS_IN = 'is in',
    IS_NOT_IN = 'is not in',
}
export enum EngagementAPIOperator {
    CONTAINS = 'contains',
    DOES_NOT_CONTAIN = 'does not contain',
}
export enum EngagementUIOperator {
    DID_CLICK = 'did click',
    DID_OPEN = 'did open',
    DID_RECEIVE = 'did receive',
    DID_NOT_CLICK = 'did not click',
    DID_NOT_OPEN = 'did not open',
    DID_NOT_RECEIVE = 'did not receive',
}
export enum EngagementName {
    CLICKED_SENDS = 'clicked_sends',
    RECEIVED_SENDS = 'received_sends',
    OPENED_SENDS = 'opened_sends',
}
export enum RelativeDateRangeDirection {
    THE_LAST = 'the last',
    THE_NEXT = 'the next',
}
export enum EventSelector {
    INCLUDES = 'includes',
    DOES_NOT_INCLUDE = 'does not include',
}
export enum DemographicContainsOperator {
    INCLUDES_ANY = 'includes any',
    INCLUDES_ALL = 'includes all',
    EXCLUDES_ANY = 'excludes any',
    EXCLUDES_ALL = 'excludes all',
}
export enum NullOperator {
    IS_NULL = 'is null',
    IS_NOT_NULL = 'is not null',
}
export const DemographicContainsOperatorHelpText = {
    [DemographicContainsOperator.INCLUDES_ANY]: 'The field includes at least one of the following values.',
    [DemographicContainsOperator.INCLUDES_ALL]: 'The field includes all of the following values.',
    [DemographicContainsOperator.EXCLUDES_ANY]: 'The field includes none of the following values.',
    [DemographicContainsOperator.EXCLUDES_ALL]: 'The field does not include all of the following values.',
};

export type DateRange = { start: string; end: string };
export type RelativeDateRangeValueAndUnit = { value: number; unit: 'hour' | 'day' | 'month' | 'year' };

export type RelativeDateRange = {
    offset: RelativeDateRangeValueAndUnit;
    duration: RelativeDateRangeValueAndUnit;
};

export type SegmentDemographicValue = string | string[] | number | number[] | boolean | DateRange | RelativeDateRange;

export type SegmentEngagementValue = number | string;

export type SegmentFilterValue = SegmentDemographicValue | SegmentEngagementValue;

export type SegmentFilterDemographicFields = {
    value: SegmentDemographicValue;
};

export type SegmentEngagementLinkInput = Pick<
    SegmentFilterState<SegmentFilterEngagementFields> & SegmentFilterEngagementFields,
    'autocompleteInputValue' | 'autocompleteValue' | 'value'
>;

export type SegmentFilterEngagementFields = {
    value: SegmentEngagementValue;
    segmentLinkInput?: SegmentEngagementLinkInput;
};

export type SegmentFilterEventCondition = Omit<SegmentDemographicInput, 'operator'> & {
    operator: SegmentFilterState<SegmentFilterEventFields>['operator'];
};

export type SegmentFilterEventFields = {
    // TODO: This operator was needed for handling event filter for users without ESE FF that save date range & date time value
    // TODO: dateRangeDateTimeOperator can be removed when ESE FF has been removed
    dateRangeDateTimeOperator?: DateRangeOperator | TimestampOperator;
    // TODO: value can be removed when ESE FF has been removed
    value: SegmentDemographicValue;
    conditions?: SegmentFilterEventCondition[];
};

export type SegmentFilterCategorySpecificField =
    | SegmentFilterDemographicFields
    | SegmentFilterEngagementFields
    | SegmentFilterEventFields;

export type SegmentFilterState<T extends SegmentFilterCategorySpecificField> = {
    category: FilterCategory;
    categorySpecificFields: T;
    id: number;
    name: string;
    operator:
        | SegmentDemographic['operator']
        | EngagementUIOperator
        | EventSelector
        | InclusionUIOperator
        | DemographicContainsOperator;
    autocompleteInputValue?: string;
    autocompleteValue?: AutocompleteOption<SegmentFilterValue> | AutocompleteOption<SegmentFilterValue>[];
};

// API types
export type SegmentDemographicInput = Omit<SegmentDemographic, 'sqlType'>;

export type SegmentEventInput = Omit<SegmentEvent, 'conditions'> & {
    conditions: SegmentDemographicInput[];
    selector: string;
    operator?: string;
};

export type SegmentDefinitionInput = {
    matchType: 'AND' | 'OR';
    contactRecordType?: RecordTypeFilter['recordType'][];
    contactEvent: SegmentEventInput[];
    demographic: SegmentDemographicInput[];
    engagement: SegmentEngagement[];
};

export type SegmentFilter = {
    displayName: string;
    name: string;
    sqlType: SQLType;
    valuesRef: string;
};

export type RecordTypeFilter = {
    recordType: string;
    filters: SegmentFilter[];
};
export type RecordTypeSegmentFilter = {
    displayName: string;
    recordTypes: RecordTypeFilter[];
};

export type SegmentFilterRecordCollection = {
    contactEvent: RecordTypeSegmentFilter;
    demographic: RecordTypeSegmentFilter;
    engagement: RecordTypeSegmentFilter;
};

export type SegmentFilterCollection = {
    contactEvent: {
        displayName: string;
        filters: SegmentFilter[];
    };
    demographic: {
        displayName: string;
        filters: SegmentFilter[];
    };
    engagement: {
        displayName: string;
        filters: SegmentFilter[];
    };
};

export type SegmentEvent = {
    conditions: SegmentDemographic[];
    selector: string;
    operator?: string;
};

export type SegmentDemographic = {
    name: string;
    operator:
        | NullOperator
        | BooleanOperator
        | DateOperator
        | DateRangeOperator
        | NumericOperator
        | TimestampOperator
        | PercentileOperator
        | InclusionAPIOperator
        | DemographicContainsOperator;
    sqlType?: SQLType;
    value: SegmentDemographicValue;
    autocompleteInputValue?: string;
    autocompleteValue?: AutocompleteOption<SegmentFilterValue> | AutocompleteOption<SegmentFilterValue>[];
};

export type SegmentEngagement = {
    name: EngagementName;
    operator: EngagementAPIOperator;
    value: number;
    segmentLinkValue?: string;
};

export type SegmentDefinition = {
    matchType: 'AND' | 'OR';
    demographic: SegmentDemographic[];
    engagement: SegmentEngagement[];
    contactEvent: SegmentEvent[];
    contactRecordType?: RecordTypeFilter['recordType'][];
    segmentId?: number;
    negateQuery?: boolean;
};

export type Segment = {
    id: number;
    createdTimestamp: string;
    lastModifiedTimestamp: string;
    name: string;
    segmentDefinition: SegmentDefinition;
};

export const isDateRangeOperator = (operator: SegmentDemographic['operator'] | string): operator is DateRangeOperator =>
    Object.values(DateRangeOperator).includes(operator as DateRangeOperator);

export const isInclusionAPIOperator = (
    operator: SegmentDemographic['operator'] | string
): operator is InclusionAPIOperator => Object.values(InclusionAPIOperator).includes(operator as InclusionAPIOperator);

export const isInclusionUIOperator = (
    operator: SegmentDemographic['operator'] | string
): operator is InclusionUIOperator => Object.values(InclusionUIOperator).includes(operator as InclusionUIOperator);

export const isPercentileOperator = (
    operator: SegmentDemographic['operator'] | string
): operator is PercentileOperator => Object.values(PercentileOperator).includes(operator as PercentileOperator);

export const isDemographicContainsOperator = (
    operator: SegmentDemographic['operator'] | string
): operator is DemographicContainsOperator =>
    Object.values(DemographicContainsOperator).includes(operator as DemographicContainsOperator);

export const isMultiInputOperator = (operator: SegmentDemographic['operator'] | string): boolean =>
    isInclusionUIOperator(operator) || isDemographicContainsOperator(operator);

export const isDateRangeType = (value: SegmentFilterValue): value is DateRange =>
    typeof value === 'object' && value !== null && ('start' in value || 'end' in value);

export const isRelativeDateRangeType = (value: SegmentFilterValue): value is RelativeDateRange =>
    typeof value === 'object' && value !== null && ('offset' in value || 'duration' in value);
