import LineGraph from 'pages/InsightsPage/visualizations/LineGraph';
import Card from '../Card';
import useContactSubscribes from './useContactSubs';

const SubscribersOverTimeCard = () => {
    const { data, isError, isLoading } = useContactSubscribes();

    const renderGraph = () => {
        if (isLoading) return 'Loading...';
        if (isError) return 'Something went wrong.';
        return (
            <LineGraph
                data={[data]}
                leftLegendLabel="new subscribers"
                tickValues="every 2 months"
                tooltipLabel="rolling sum"
            />
        );
    };

    return <Card label="Email Subscribers over Time">{renderGraph()}</Card>;
};

export default SubscribersOverTimeCard;
