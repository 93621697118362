import { useCallback } from 'react';
import { useReactFlow, useStoreApi } from 'reactflow';

const Y_OFFSET_RATIO = 5 / 16;
const VIEWPORT_OPTIONS = { duration: 500 };

/**
 * Returns a function to center a node vertically.
 */
const useVerticallyCenterNode = () => {
    const { setViewport } = useReactFlow();
    const store = useStoreApi();

    /**
     * Centers the react-flow node vertically in the react-flow view.
     * @param nodeId the index of the node to center on
     */
    const centerNodeVertically = useCallback(
        (nodeId: string) => {
            const { nodeInternals, height } = store.getState();

            const nodes = Array.from(nodeInternals).map(([, node]) => node);
            const targetNode = nodes.find((currentNode) => currentNode.id === nodeId);
            const isDisabled = targetNode.data?.isDisabled;

            if (nodes.length > 0 && !isDisabled) {
                const x = 0;
                const y = -targetNode.position.y + height * Y_OFFSET_RATIO;

                setViewport(
                    {
                        x,
                        y,
                        zoom: 1,
                    },
                    VIEWPORT_OPTIONS
                );
            }
        },
        [setViewport, store]
    );

    return centerNodeVertically;
};

export default useVerticallyCenterNode;
