import { SQLType } from 'models/enums';
import TextField from 'domains/core/components/TextField';
import { SegmentDemographicValue } from 'domains/segments/types';
import { getInputType } from '../../utils';

type Props = {
    placeholderName: string;
    sqlType: SQLType;
    value: SegmentDemographicValue;
    onChange: (value: any) => void;
};

const TextFieldInput = ({ placeholderName, sqlType, value, onChange }: Props) => (
    <TextField
        data-testid="valueTextField"
        label="Value"
        placeholder={`Enter a value for ${placeholderName}`}
        style={{
            width: '16rem',
        }}
        type={getInputType(sqlType)}
        value={value}
        onChange={(e) => onChange(e.target.value)}
    />
);

export default TextFieldInput;
