import React, { useState, useRef } from 'react';
import {
    ButtonGroup,
    ClickAwayListener,
    Grow,
    Paper,
    Popper,
    MenuItem,
    MenuList,
    Box,
    Typography,
} from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import tabArrowHead from 'assets/images/svg/tab-arrow-head.svg';
import useConfig from 'hooks/useConfig';

type DropdownButtonOption = {
    label: string;
    onClick: () => void;
};
type Props = {
    userEmail: any;
};

/**
 *
 * @param userEmail The user email used to display letters in the button.
 *
 * @returnsThe React node created by this component.
 */
const ProfileButton = ({ userEmail }: Props) => {
    const {
        config: { AUTH0_HOME_URI },
    } = useConfig();
    const { logout } = useAuth0();
    const options: DropdownButtonOption[] = [{ label: 'Log Out', onClick: () => logout({ returnTo: AUTH0_HOME_URI }) }];
    const [open, setOpen] = useState(false);
    const [isHover, setIsHover] = useState(false);
    const anchorRef = useRef<HTMLDivElement>(null);
    const handleMenuItemClick = (event: any, index: number) => {
        setOpen(false);
        options[index].onClick();
    };

    const handleToggle = () => setOpen((prevOpen) => !prevOpen);

    const handleClose = (event: any) => {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return;
        }
        setOpen(false);
    };

    return (
        <>
            <Box
                sx={isHover ? {} : { display: 'none' }}
                onMouseOver={() => {
                    setIsHover(true);
                }}
                onMouseLeave={() => {
                    setIsHover(false);
                }}
            >
                <Box
                    display="flex"
                    zIndex={6}
                    position="fixed"
                    left="3rem"
                    bottom="0.5rem"
                    paddingTop="0.1875rem"
                    height="2.875rem"
                >
                    <img height="100%" src={tabArrowHead} alt="Log out of the Cured app." />
                </Box>
                <Box
                    zIndex={6}
                    position="fixed"
                    marginTop="0.1875rem"
                    paddingRight="1rem"
                    left="5rem"
                    bottom="0.5rem"
                    height="2.875rem"
                    borderRadius="0.5rem"
                    width="fit-content"
                    sx={{ backgroundColor: 'action.active' }}
                >
                    <Box
                        height="3.25rem"
                        display="flex"
                        justifyContent="center"
                        alignContent="center"
                        paddingTop="0.75rem"
                        zIndex={6}
                    >
                        <Typography variant="tooltip" sx={{ color: 'white' }}>
                            Log out here
                        </Typography>
                    </Box>
                </Box>
            </Box>
            <Box
                position="fixed"
                left="0rem"
                bottom="0rem"
                display="flex"
                flexDirection="row"
                justifyContent="center"
                width="4rem"
                marginBottom={1.75}
            >
                <ButtonGroup ref={anchorRef}>
                    <Box
                        marginLeft="0.25rem"
                        onMouseOver={() => {
                            setIsHover(true);
                        }}
                        onMouseLeave={() => {
                            setIsHover(false);
                        }}
                    >
                        <Typography
                            data-test="logout-menu"
                            sx={{
                                height: '2rem',
                                width: '2rem',
                                borderRadius: '50%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                border: 'solid black 1px',
                                cursor: 'pointer',
                                zIndex: 8,
                            }}
                            variant="body2"
                            color="white"
                            bgcolor="#009C70"
                            onClick={handleToggle}
                            data-testid="profile-button"
                        >
                            {userEmail.slice(0, 2).toUpperCase()}
                        </Typography>
                    </Box>
                </ButtonGroup>
                <Popper
                    style={{
                        width: '110.27px',
                        zIndex: 8,
                    }}
                    open={open}
                    anchorEl={anchorRef.current}
                    role={undefined}
                    transition
                >
                    {({ TransitionProps }) => (
                        <Grow
                            {...TransitionProps}
                            style={{
                                transformOrigin: 'bottom left',
                            }}
                        >
                            <Paper sx={{ position: 'absolute', left: 14, bottom: 45 }}>
                                <ClickAwayListener onClickAway={handleClose}>
                                    <MenuList>
                                        <MenuItem
                                            sx={{
                                                cursor: 'default',
                                                '&:hover': {
                                                    bgcolor: 'white',
                                                },
                                            }}
                                            key={userEmail}
                                        >
                                            <Typography
                                                sx={{
                                                    height: '2rem',
                                                    width: '2rem',
                                                    borderRadius: '50%',
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    border: 'solid black 1px',
                                                    mr: '1rem',
                                                }}
                                                variant="body2"
                                                color="white"
                                                bgcolor="#009C70"
                                            >
                                                {userEmail.slice(0, 2).toUpperCase()}
                                            </Typography>
                                            <Typography variant="body1" color="#009C70">
                                                {userEmail}
                                            </Typography>
                                        </MenuItem>
                                        {options.map((option, index) => (
                                            <MenuItem
                                                data-test="logout-button"
                                                key={option.label}
                                                onClick={(event) => {
                                                    setIsHover(false);
                                                    handleMenuItemClick(event, index);
                                                }}
                                            >
                                                <Typography variant="body1">{option.label}</Typography>
                                            </MenuItem>
                                        ))}
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </Box>
        </>
    );
};

export default ProfileButton;
