import { Stack } from '@mui/material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useCallback, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import IconButton from 'domains/core/components/IconButton';
import Table, { ColumnOption, renderDate } from 'domains/core/components/Table';
import SegmentNameInput from 'domains/segments/components/SegmentNameInput';
import { Segment } from 'domains/segments/types';
import { MutationStatus, URLPaths } from 'models/enums';
import SegmentDetails from './SegmentDetails';
import { hasInvalidRelativeDates } from 'domains/segments/utils';
import downloadFile from 'helpers/downloadFile/downloadFile';
import useMutationStatus from 'hooks/useMutationStatus';
import MutationKeys from 'hooks/mutations/keys';
import useDeleteSegment from 'hooks/mutations/useDeleteSegment';
import useFeatureSettings from 'hooks/queries/useFeatureSettings';
import useContacts from 'hooks/queries/useContacts';
import MoreOptionsDropdown from 'domains/core/components/MoreOptionsDropdown/MoreOptionsDropdown';
import ErrorSnackbar from 'domains/core/components/Snackbars/ErrorSnackbar/ErrorSnackbar';
import SuccessSnackbar from 'domains/core/components/Snackbars/SuccessSnackbar/SuccessSnackbar';
import InfoSnackbar from 'domains/core/components/Snackbars/InfoSnackbar/InfoSnackbar';
import ConfirmationDialog from 'domains/core/components/ConfirmationDialog';

const renderRowSubComponent = (row: any) => <SegmentDetails segment={row.original} />;

type Props = {
    isRefetching: boolean;
    refetch: () => void;
    segments: Segment[];
};

const allContactsRowName = 'All Contacts';

/**
 * A table used for displaying audiences in the audiences page.
 * @returns The React node created by this component.
 */
const SegmentsTable = ({ isRefetching, refetch, segments }: Props) => {
    const [audienceToDownload, setAudienceToDownload] = useState(null);
    const [audienceToDelete, setAudienceToDelete] = useState(null);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    const history = useHistory();

    const { audienceDelete: hasAudienceDeleteFeature } = useFlags();

    const {
        isLoading: isFeatureSettingsLoading,
        data: featureSettings,
        isSuccess: isFeatureSettingsSuccess,
    } = useFeatureSettings();

    const {
        isLoading: isContactsDownloadLoading,
        isError: isContactsDownloadError,
        isSuccess: isContactsDownloadSuccess,
    } = useContacts({
        downloadCSV: true,
        options: {
            limit: 'all',
            segmentId: audienceToDownload?.id,
        },
        reactQueryOptions: {
            enabled: !!audienceToDownload,
            onError: () => {
                setErrorMessage(`Problem with downloading "${audienceToDownload?.name}." Try again.`);
            },
            onSuccess: (csvFile) => {
                setSuccessMessage(`"${audienceToDownload?.name}" successfully downloaded.`);
                downloadFile({
                    file: csvFile.toString(),
                    fileType: 'csv',
                    fileName: audienceToDownload?.name,
                });
            },
        },
    });

    const { isLoading: isDeleteSegmentLoading, mutate: deleteSegment } = useDeleteSegment(`${audienceToDelete?.id}`, {
        onError: (error: Error) => setErrorMessage(error.message),
        onSuccess: () => {
            setSuccessMessage(`"${audienceToDelete.name}" successfully deleted.`);
            refetch();
        },
    });

    const toggleDeleteModalOpen = useCallback(
        (segment?: Segment, isConfirm?: boolean) => {
            isConfirm ? deleteSegment() : setAudienceToDelete(segment);
            setIsDeleteModalOpen(!!segment);
        },
        [deleteSegment]
    );

    const data: Segment[] = useMemo(
        () =>
            segments?.map((segment) => ({
                ...segment,
                dateCreated: new Date(segment.createdTimestamp),
            })),
        [segments]
    );

    const columns: ColumnOption[] = useMemo(() => {
        const nameColumn: ColumnOption = {
            Header: 'name',
            accessor: 'name',
            align: 'left',
            sortType: 'string',
            Cell: (value: any) => (
                <Stack direction="row" alignItems="center" columnGap={1}>
                    <IconButton
                        ariaLabel="show audience details"
                        handleClick={() => {
                            value.row.toggleRowSelected();
                            value.row.getToggleRowExpandedProps().onClick();
                        }}
                        Icon={value.row.isExpanded ? ExpandLessIcon : ExpandMoreIcon}
                        color="primary"
                    />
                    {value.cell.row.original.name === allContactsRowName ? (
                        <>{value.cell.value}</>
                    ) : (
                        <SegmentNameInput id={value.cell.row.original.id} name={value.cell.value} />
                    )}
                </Stack>
            ),
        };

        const dateCreatedColumn: ColumnOption = {
            Header: 'date created',
            accessor: 'dateCreated',
            sortType: 'datetime',
            Cell: renderDate,
        };

        const moreOptionsColumn: ColumnOption = {
            Header: '',
            accessor: 'options',
            align: 'right',
            disableSortBy: true,
            Cell: (value: any) => {
                const segment = value.row.original;

                const isDeleteEnabled =
                    hasAudienceDeleteFeature && value.row.values.name !== allContactsRowName && segment.canDelete;
                const isDownloadEnabled = isFeatureSettingsSuccess && featureSettings?.allow_mass_contact_retrieval;

                return (
                    <MoreOptionsDropdown
                        menuItems={[
                            {
                                name: 'View Contacts',
                                onClick: () => {
                                    history.push(URLPaths.SEGMENTS_CONTACTS, {
                                        segmentId: segment.id,
                                        segmentName: segment.name,
                                    });
                                },
                            },
                            {
                                disabled: hasInvalidRelativeDates(value.row.values),
                                name: 'Copy Audience Filters',
                                onClick: () => {
                                    history.push(URLPaths.SEGMENT_CREATE, {
                                        segmentDefinition: segment.segmentDefinition,
                                    });
                                },
                            },
                            ...(isDownloadEnabled
                                ? [
                                      {
                                          name: 'Download Contacts',
                                          onClick: () => setAudienceToDownload(segment),
                                      },
                                  ]
                                : []),
                            ...(isDeleteEnabled
                                ? [
                                      {
                                          name: 'Delete',
                                          onClick: () => toggleDeleteModalOpen(segment),
                                      },
                                  ]
                                : []),
                        ]}
                    />
                );
            },
        };
        return [nameColumn, dateCreatedColumn, moreOptionsColumn];
    }, [
        hasAudienceDeleteFeature,
        isFeatureSettingsSuccess,
        featureSettings?.allow_mass_contact_retrieval,
        history,
        toggleDeleteModalOpen,
    ]);

    const stickyRowFilter = useCallback((row: any) => row.original.name === allContactsRowName, []);

    const deleteSegmentStatus = useMutationStatus(MutationKeys.DELETE_SEGMENT);

    const isError = isContactsDownloadError || deleteSegmentStatus === MutationStatus.ERROR;
    const isLoading = isContactsDownloadLoading || isDeleteSegmentLoading;
    const isSuccess = isContactsDownloadSuccess || deleteSegmentStatus === MutationStatus.SUCCESS;

    const infoMessage = isContactsDownloadLoading
        ? `Downloading "${audienceToDownload?.name}"`
        : isDeleteSegmentLoading
        ? `Deleting "${audienceToDelete?.name}"`
        : '';

    if (isFeatureSettingsLoading) return;

    return (
        <>
            {isError && <ErrorSnackbar errorMessage={errorMessage} />}
            {isLoading && <InfoSnackbar successMessage={infoMessage} />}
            {isSuccess && <SuccessSnackbar successMessage={successMessage} />}
            <ConfirmationDialog
                dialogProps={{
                    confirmButtonText: 'Delete',
                    contentText: `You are about to delete "${audienceToDelete?.name}." This action cannot be undone.`,
                    title: 'Delete Audience',
                }}
                open={isDeleteModalOpen}
                onConfirm={() => toggleDeleteModalOpen(null, true)}
                onModalClose={() => toggleDeleteModalOpen()}
            />
            <Table
                columns={columns}
                data={data}
                defaultSortingRules={[{ id: 'dateCreated', desc: true }]}
                isRefetching={isRefetching}
                stickyRow
                stickyRowFilter={stickyRowFilter}
                renderRowSubComponent={renderRowSubComponent}
                refetch={refetch}
            />
        </>
    );
};

export default SegmentsTable;
