import { Box, Typography } from '@mui/material';

const cardStyles = {
    border: '1px solid',
    borderColor: 'primary.light',
    borderRadius: 1,
    height: '100%',
};

const Card = ({ children, label, sx }: any) => (
    <Box {...cardStyles} textAlign="center" {...sx}>
        {label && (
            <Typography variant="body2" mt={2} fontWeight="bold">
                {label}
            </Typography>
        )}
        {children}
    </Box>
);

export default Card;
