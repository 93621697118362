import PieChart from 'pages/InsightsPage/visualizations/PieChart';
import Card from '../Card';
import useContactSubStatus from './useContactSubStatus';

const SubscriptionStatusCard = () => {
    const { data, isError, isLoading } = useContactSubStatus();

    const renderChart = () => {
        if (isLoading) return 'Loading...';
        if (isError) return 'Something went wrong.';
        return <PieChart data={data} />;
    };

    return <Card label="Subscription Status">{renderChart()}</Card>;
};

export default SubscriptionStatusCard;
