import { CuredApiPaths, SQLType } from './enums';

/**
 *  Contact types
 */
export type Contact = {
    birth_date: string;
    cured_id: string;
    email: string;
    first_name: string;
    administrative_gender: string;
    last_name: string;
    most_recent_visit: string;
    preferred_contact_method: string;
    race: string;
    status: string;
    street_address: string;
    street_address_2: string;
    zip_code: string;
    state_code: string;
    phone_number: string;
};

export type ContactColumn = {
    columnName: string;
    sqlType: SQLType;
    isCustom: boolean;
    isMergeTag: boolean;
    isPHI: boolean;
    isSegmentable: boolean;
    personalizationDefault?: string;
    personalizationPreview?: string;
};

/**
 *  Insights types
 */

export type ReportDetails = {
    embedUrl: Record<string, string>;
    embedToken: Record<string, string>;
};

export type DataVisUrl = {
    url?: string;
    reportDetails?: ReportDetails;
};

/**
 * Data imports representing file uploads
 */
export type Import = {
    jobId: number;
    jobTimestamp: string;
    jobType: string;
    success: boolean;
    errorMessage: string;
    fileLocation: string;
    importChanges: string;
};

/**
 * Error codes from API mapped to a user friendly message.
 */
export const ErrorCodes: {
    [errorCode: string]: string;
} = {
    DEFAULT: 'Something went wrong.',
    'e91dd59c-1768-491b-b0c2-ff6897e35bc2': 'Send time cannot be in the past or greater than 1 year out.',
    '4e3bb058-c349-4432-80d2-b84a84e05569': 'You entered a duplicate curation name.',
    '6518de5c-5b54-4854-b429-6fdfaf784866': 'Your email must include a name.',
    '5f464710-ff7a-4b5a-bf69-504f6799a770': 'Your email must include an unsubscribe footer.',
    'becc39e3-754a-4bd7-adb9-1c809c34f2ab': 'Your campaign must be scheduled for the future.',
    'abcc9723-5558-4fbe-b064-2704d50fef9e': 'Your file name is invalid.',
};

/**
 * General types
 */
export type Action = {
    type: string;
    payload?: any;
};

export type ColumnInfo = {
    id: number;
    name: string;
    type: string;
    shouldInclude: boolean;
};

export type Config = {
    API_URL: string;
    AUTH0_DOMAIN: string;
    AUTH0_CLIENT_ID: string;
    AUTH0_AUDIENCE: string;
    AUTH0_AUDIENCE_ENDPOINT: string;
    AUTH0_REDIRECT_URI: string;
    AUTH0_HOME_URI: string;
    LAUNCH_DARKLY_CLIENT_SIDE_ID: string;
    SATISMETER_WRITE_KEY?: string;
    SATISMETER_ENDPOINT?: string;
    FULLSTORY_ORG_ID?: string;
};

export type UseConfigResult = {
    config?: Config;
    loading: boolean;
};

export type RecursivePartial<T> = {
    [P in keyof T]?: T[P] extends (infer U)[]
        ? RecursivePartial<U>[]
        : T[P] extends object
        ? RecursivePartial<T[P]>
        : T[P];
};

export type FeatureSettings = {
    enabled_dashboards?: string[];
    sms_enabled?: boolean;
    allow_mass_contact_retrieval?: boolean;
};

export class PathWithID {
    // Default is used as an id in for the getting the default template, otherwise we don't want to allow arbitrary strings.
    constructor(readonly path: CuredApiPaths, readonly id: number | string | 'default') {}

    get url(): string {
        return `${this.path}/${this.id}`;
    }
}

export type Tag = {
    id: number;
    name: string;
};
