import { Config } from 'models/types';

const configLocalDev: Config = {
    API_URL: 'http://localhost:8080/',
    AUTH0_DOMAIN: 'cured-dev.us.auth0.com',
    AUTH0_CLIENT_ID: 'ZiBaKtu3fX2yfnh6fySxkBbGyeR6zzrm',
    AUTH0_AUDIENCE: 'https://cured-alpha-dev-api.curedhealth.io/',
    AUTH0_AUDIENCE_ENDPOINT: 'https://cured.health/roles',
    AUTH0_REDIRECT_URI: 'http://localhost:3000',
    AUTH0_HOME_URI: 'http://localhost:3000',
    LAUNCH_DARKLY_CLIENT_SIDE_ID: '60d0e0841b0bf60d9a1f964b',
    SATISMETER_WRITE_KEY: 'I77DYSXJCUmPhSeh8Fbp1oPSByZkDWOU',
    SATISMETER_ENDPOINT: 'https://app.satismeter.com/js',
    FULLSTORY_ORG_ID: 'o-1YWP06-na1',
};

export default configLocalDev;
