import {
    SegmentDemographicValue,
    SegmentFilter,
    SegmentFilterEventFields,
    SegmentFilterState,
} from 'domains/segments/types';
import FilterValueInput from '../../../FilterValueInput';
import { getNumericOrStringValue } from '../../../utils';
import { AutocompleteOption } from 'domains/core/components/Autocomplete/Autocomplete';

type Props = {
    conditionIndex: number;
    filterIndex: number;
    filterOptions: SegmentFilter[];
    filterState: SegmentFilterState<SegmentFilterEventFields>;
    handleDatetimeError: (filterId: string, error: boolean) => void;
    updateSegmentFilter: (
        index: number,
        filter: SegmentFilterState<SegmentFilterEventFields>,
        resetPreview?: boolean
    ) => void;
};

const ValueInput = ({
    conditionIndex,
    filterIndex,
    filterOptions,
    filterState,
    handleDatetimeError,
    updateSegmentFilter,
}: Props) => {
    const { categorySpecificFields } = filterState;
    const { conditions } = categorySpecificFields;

    const activeCondition = conditions[conditionIndex];

    const filterOption = filterOptions.find((filterOption) => filterOption.name === activeCondition.name);

    const handleChangeValue = (value: any) => {
        const newConditions = conditions.map((condition, index) => {
            if (index !== conditionIndex) return condition;

            const newValue = getNumericOrStringValue(value, filterOption?.sqlType);

            condition = {
                ...condition,
                autocompleteInputValue: `${newValue}`,
                value: newValue,
            };
            return condition;
        });

        updateSegmentFilter(filterIndex, {
            ...filterState,
            categorySpecificFields: {
                ...categorySpecificFields,
                conditions: newConditions,
            },
        });
    };

    const handleChangeAutocompleteValue = (
        autocompleteValue: any | AutocompleteOption<string>[],
        resetPreview?: boolean
    ) => {
        const getOption = (value: string | AutocompleteOption<string>) => {
            if (!!value && typeof value === 'object' && 'label' in value && 'value' in value) return value;
            return { label: value as string, value: value as string };
        };

        const newConditions = conditions.map((condition, index) => {
            if (index !== conditionIndex) return condition;

            const hasMultipleValues = Array.isArray(autocompleteValue);

            const newAutocompleteInputValue = hasMultipleValues ? '' : autocompleteValue?.value ?? autocompleteValue;

            const newAutocompleteValue: AutocompleteOption<string> | AutocompleteOption<string>[] = hasMultipleValues
                ? autocompleteValue.map((autocompleteValue) => getOption(autocompleteValue))
                : getOption(autocompleteValue);

            const newValue = hasMultipleValues
                ? (newAutocompleteValue as AutocompleteOption<string>[]).map((autocompleteValue) =>
                      getNumericOrStringValue(autocompleteValue?.value, filterOption?.sqlType)
                  )
                : getNumericOrStringValue(newAutocompleteInputValue, filterOption?.sqlType);

            condition = {
                ...condition,
                value: newValue as SegmentDemographicValue,
                autocompleteInputValue: newAutocompleteInputValue,
                autocompleteValue: newAutocompleteValue,
            };

            return condition;
        });

        updateSegmentFilter(
            filterIndex,
            {
                ...filterState,
                categorySpecificFields: {
                    ...categorySpecificFields,
                    conditions: newConditions,
                },
            },
            resetPreview
        );
    };

    const handleChangeAutocompleteInputValue = (autocompleteInputValue: string, hasMultipleValues: boolean) => {
        const newConditions = conditions.map((condition, index) => {
            if (index !== conditionIndex) return condition;

            const value = !hasMultipleValues && {
                value: autocompleteInputValue,
            };

            condition = {
                ...condition,
                ...value,
                autocompleteInputValue,
            };
            return condition;
        });

        updateSegmentFilter(filterIndex, {
            ...filterState,
            categorySpecificFields: {
                ...categorySpecificFields,
                conditions: newConditions,
            },
        });
    };

    return (
        <div>
            <FilterValueInput
                activeCondition={activeCondition}
                filterOption={filterOption}
                filterState={filterState}
                handleDatetimeError={handleDatetimeError}
                handleChangeValue={handleChangeValue}
                handleChangeAutocompleteValue={handleChangeAutocompleteValue}
                handleChangeAutocompleteInputValue={handleChangeAutocompleteInputValue}
            />
        </div>
    );
};

export default ValueInput;
