import { ResponsivePie } from '@nivo/pie';

type DataItem = {
    id: string | number;
    label?: string;
    value: number;
    percentage?: string;
};

type Props = {
    data: DataItem[];
};

const PieChart = ({ data }: Props) => {
    const getFormattedLabel = (datum: any) => {
        if (typeof datum.value !== 'number') return datum.value;

        const formattedLabel = Intl.NumberFormat('en-US', {
            notation: 'compact',
            maximumFractionDigits: 1,
        }).format(datum.value);

        return formattedLabel;
    };

    return (
        <div style={{ height: '400px', width: '100%' }}>
            <ResponsivePie
                arcLabel={(datum: any) => (datum.data.percentage > 1 ? `${datum.data.percentage}%` : '')}
                arcLinkLabel={(datum) => getFormattedLabel(datum)}
                arcLinkLabelsColor={{ from: 'color' }}
                arcLinkLabelsDiagonalLength={8}
                arcLinkLabelsStraightLength={16}
                arcLinkLabelsSkipAngle={10}
                arcLinkLabelsThickness={2}
                borderColor={{
                    from: 'color',
                    modifiers: [['darker', 0.2]],
                }}
                borderWidth={1}
                colors={{ scheme: 'set2' }}
                data={
                    data ?? [
                        {
                            id: 'java',
                            label: 'java',
                            value: 511,
                        },
                        {
                            id: 'python',
                            label: 'python',
                            value: 237,
                        },
                        {
                            id: 'erlang',
                            label: 'erlang',
                            value: 589,
                        },
                        {
                            id: 'rust',
                            label: 'rust',
                            value: 65,
                        },
                        {
                            id: 'stylus',
                            label: 'stylus',
                            value: 561,
                        },
                    ]
                }
                isInteractive={false}
                legends={[
                    {
                        anchor: 'right',
                        direction: 'column',
                        justify: false,
                        translateX: 90,
                        translateY: 0,
                        itemsSpacing: 4,
                        itemWidth: 10,
                        itemHeight: 18,
                        itemOpacity: 1,
                        symbolSize: 16,
                        symbolShape: 'circle',
                    },
                ]}
                margin={{ top: 40, right: 250, bottom: 70, left: 85 }}
            />
        </div>
    );
};

export default PieChart;
