import { Location } from 'history';
import { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useHistory } from 'react-router';
import { v4 as uuidv4 } from 'uuid';
import {
    CampaignChannels,
    EmailSendInput,
    EmailSendInputState,
    ExitActionInput,
    SplitActions,
    SplitTypes,
} from 'domains/campaigns/types';
import { Template } from 'domains/content/types';
import Flow from 'domains/core/components/Flow';
import RouteLeavingGuard from 'domains/core/components/RouteLeavingGuard';
import ErrorSnackbar from 'domains/core/components/Snackbars/ErrorSnackbar';
import SuccessDialog from 'domains/core/components/SuccessDialog';
import { recordSatismeterEvent } from 'helpers/injectSatismeter/injectSatismeter';
import useCreateCampaign from 'hooks/mutations/useCreateCampaign';
import QueryKeys from 'hooks/queries/keys';
import useTemplates from 'hooks/queries/useTemplates';
import { URLPaths } from 'models/enums';
import { removeSend, setName } from './customCampaignActions';
import CustomCampaignContentPreview from './CustomCampaignContentPreview';
import CustomCampaignForm from './CustomCampaignForm';
import CustomCampaignNameInput from './CustomCampaignNameInput';
import CustomCampaignNavigation from './CustomCampaignNavigation';
import CustomCampaignNavigationButtons from './CustomCampaignNavigationButtons';
import { StateTypes } from './customCampaignReducer';
import { getSegmentsForApi } from './utils';
import { campaignDialogProps } from 'domains/core/components/RouteLeavingGuard/RouteLeavingGuard';

export enum CustomCampaignSteps {
    NAME_CAMPAIGN = 'name-campaign',
    SELECT_AUDIENCE = 'select-audience',
    SELECT_CONTENT = 'select-content',
    SCHEDULE_CONTENT = 'schedule-content',
    CHOOSE_NEXT_STEP = 'choose-next-step',
    SET_TIME_DELAY = 'set-time-delay',
    CHOOSE_SEND_TYPE = 'choose-send-type',
    CONDITIONAL_SPLIT = 'conditional-split',
    SELECT_PATH_ACTION = 'select-path-action',
    PUBLISH_CAMPAIGN = 'publish-campaign',
}

const CustomCampaign = ({
    campaignState,
    dispatchCampaignAction,
}: {
    campaignState: StateTypes;
    dispatchCampaignAction: React.Dispatch<any>;
}) => {
    // Navigation State Values
    const [activeStep, setActiveStep] = useState(CustomCampaignSteps.NAME_CAMPAIGN);
    const [nextStep, setNextStep] = useState(CustomCampaignSteps.SELECT_AUDIENCE);
    const [activeSendIndex, setActiveSendIndex] = useState(0);

    const [contentPreviewId, setContentPreviewId] = useState(null);

    const [isContinueEnabled, setIsContinueEnabled] = useState(false);
    const [isSuccessDialogOpen, setIsSuccessDialogOpen] = useState(false);

    const history = useHistory();
    const queryClient = useQueryClient();

    const { data: templates } = useTemplates();

    const {
        mutate,
        isError: isCreateCampaignError,
        isLoading: isCreateCampaignLoading,
        error: createCampaignError,
    } = useCreateCampaign({
        onSuccess: () => {
            // Invalidate queries used for the sends and campaigns tables
            queryClient.invalidateQueries(QueryKeys.CAMPAIGNS);
            setIsSuccessDialogOpen(true);
        },
    });

    // Closing content preview when switching steps
    useEffect(() => {
        setContentPreviewId(null);
    }, [activeStep]);

    const getSendName = (templateId: number) => {
        const currentTemplate = templates?.find((template: Template) => template.id === templateId);
        const uuid = uuidv4();
        return `${campaignState.name} - ${currentTemplate.name}.${uuid}`;
    };

    const getSendForApi = (send: EmailSendInputState, index: number): EmailSendInput | ExitActionInput => {
        if (send?.splitAction === SplitActions.EXIT) {
            const { parentIndex, splitAction, splitType } = send;
            return {
                channel: CampaignChannels.NOOP,
                parentIndex,
                splitAction,
                splitType,
            };
        }

        const name = getSendName(send.templateId);

        let sendForApi: EmailSendInput = {
            channel: send.channel,
            name: name,
            preHeader: send.preHeader,
            senderProfileId: send.senderProfileId,
            subjectLine: send.subjectLine,
            templateId: send.templateId,
        };

        if (index === 0) {
            return {
                ...sendForApi,
                recurrenceFrequency: send.recurrenceFrequency,
                scheduledEndTimestamp: send.scheduledEndTimestamp,
                scheduledTimestamp: send.scheduledTimestamp,
                segmentId: send.segmentId,
            };
        }

        // If a send has segments, we need to convert them from what the UI uses to what the API expects.
        if (!!send.segments) {
            getSegmentsForApi(send, sendForApi);
        }

        if (!!send.splitAction && !!send.splitType) {
            const { splitAction, splitType } = send;
            sendForApi = {
                ...sendForApi,
                splitAction,
                splitType,
            };
        }

        return {
            ...sendForApi,
            parentIndex: send.parentIndex,
            parentAudienceDelayInterval: send.parentAudienceDelayInterval,
        };
    };

    const onContinueClick = () => {
        if (
            activeStep === CustomCampaignSteps.SELECT_CONTENT &&
            campaignState.sends[activeSendIndex].splitType === SplitTypes.MEETS
        ) {
            setActiveSendIndex(activeSendIndex + 1);
        }
        setActiveStep(nextStep);
    };

    const onPublishClick = () => {
        const sendsForApi = campaignState.sends.map((send, index) => getSendForApi(send, index));

        const campaignStateForAPI = {
            ...campaignState,
            sends: sendsForApi,
        };

        mutate(campaignStateForAPI);
        recordSatismeterEvent('publish_curation');
    };

    const onRemoveClick = (index: number) => {
        if (index === activeSendIndex) {
            setActiveSendIndex(activeSendIndex - 1);
            setActiveStep(CustomCampaignSteps.CHOOSE_NEXT_STEP);
        }

        dispatchCampaignAction(removeSend());
    };

    const handleExitCurationWorkflow = (path: string) => {
        history.push(path);
        recordSatismeterEvent('publish_curation_popup');
    };

    const getIsNavigationBlocked = (location: Location): boolean =>
        !location.pathname.includes(URLPaths.CURATIONS_CUSTOM) && !isSuccessDialogOpen;

    return (
        <Flow
            feedback={
                <>
                    {isCreateCampaignError && !isCreateCampaignLoading && (
                        <ErrorSnackbar errorMessage={createCampaignError.message} />
                    )}
                    <RouteLeavingGuard
                        dialogProps={campaignDialogProps}
                        shouldBlockNavigation={getIsNavigationBlocked}
                    />
                    <SuccessDialog
                        title="high five!"
                        body="Nice work, you've successfully scheduled your Curation. Once it sends, visit Insights to monitor its performance."
                        isOpen={isSuccessDialogOpen}
                        setIsOpen={setIsSuccessDialogOpen}
                        onClose={() => setIsSuccessDialogOpen(false)}
                        buttonOneText="Insights"
                        buttonTwoText="Curations"
                        buttonOneOnClick={() => handleExitCurationWorkflow(URLPaths.INSIGHTS)}
                        buttonTwoOnClick={() => handleExitCurationWorkflow(URLPaths.CURATIONS)}
                    />
                </>
            }
            topLeft={
                activeStep === CustomCampaignSteps.NAME_CAMPAIGN ? null : (
                    <CustomCampaignNameInput
                        name={campaignState.name}
                        handleNameChange={(name: string) => dispatchCampaignAction(setName(name))}
                        withClickAway
                    />
                )
            }
            topRight={
                <CustomCampaignNavigationButtons
                    activeStep={activeStep}
                    isContinueEnabled={isContinueEnabled}
                    onContinueClick={onContinueClick}
                    onPublishClick={onPublishClick}
                    setIsContinueEnabled={setIsContinueEnabled}
                />
            }
            bottomLeft={
                <CustomCampaignForm
                    activeSendIndex={activeSendIndex}
                    activeStep={activeStep}
                    campaignState={campaignState}
                    contentPreviewId={contentPreviewId}
                    dispatchCampaignAction={dispatchCampaignAction}
                    setActiveSendIndex={setActiveSendIndex}
                    setActiveStep={setActiveStep}
                    setContentPreviewId={setContentPreviewId}
                    setIsContinueEnabled={setIsContinueEnabled}
                    setNextStep={setNextStep}
                />
            }
            bottomRight={
                contentPreviewId ? (
                    <CustomCampaignContentPreview
                        activeSendIndex={activeSendIndex}
                        campaignState={campaignState}
                        contentPreviewId={contentPreviewId}
                        setContentPreviewId={setContentPreviewId}
                    />
                ) : (
                    <CustomCampaignNavigation
                        activeSendIndex={activeSendIndex}
                        activeStep={activeStep}
                        campaignState={campaignState}
                        onRemoveClick={onRemoveClick}
                        setActiveSendIndex={setActiveSendIndex}
                        setActiveStep={setActiveStep}
                    />
                )
            }
        />
    );
};

export default CustomCampaign;
