import { DateTimePicker, DateTimePickerProps } from '@mui/x-date-pickers';
import { getTimezone } from 'utils';
import { DateTime } from 'luxon';
import { Stack } from '@mui/material';

type Props = {
    valueStart: DateTime;
    valueEnd: DateTime;
    onChangeValueStart: (dateTime: DateTime) => void;
    onChangeValueEnd: (dateTime: DateTime) => void;
};

const DateTimeRangePickerInputs = ({ valueStart, valueEnd, onChangeValueStart, onChangeValueEnd }: Props) => {
    const isError = valueStart > valueEnd;

    const sharedProps: DateTimePickerProps<DateTime> = {
        label: `Date/Time (${getTimezone()})`,
        slotProps: {
            textField: {
                helperText: isError && 'Please enter a valid date range',
                style: {
                    width: '16rem',
                    ...(isError && { marginBottom: '16px' }),
                },
            },
        },
    };

    return (
        <Stack direction="row" columnGap={2} data-testid="date-range-value">
            <DateTimePicker {...sharedProps} maxDateTime={valueEnd} value={valueStart} onChange={onChangeValueStart} />
            <DateTimePicker {...sharedProps} minDateTime={valueStart} value={valueEnd} onChange={onChangeValueEnd} />
        </Stack>
    );
};

export default DateTimeRangePickerInputs;
