import BarGraph from 'pages/InsightsPage/visualizations/BarGraph';
import Card from '../Card';
import useContactAge from './useContactAge';

const AgeCard = () => {
    const { data, isError, isLoading } = useContactAge();

    const renderGraph = () => {
        if (isLoading) return 'Loading...';
        if (isError) return 'Something went wrong.';
        return <BarGraph bottomLegendLabel="age range" data={data} leftLegendLabel="number of contacts" />;
    };

    return <Card label="Age">{renderGraph()}</Card>;
};

export default AgeCard;
