import Card from '../Card';
import USMap from 'pages/InsightsPage/visualizations/USMap';
import useContactLocation from './useContactLocations';

const LocationCard = () => {
    const { data, isError, isLoading } = useContactLocation();

    const renderMap = () => {
        if (isLoading) return 'Loading...';
        if (isError) return 'Something went wrong.';
        return <USMap bubbleData={data} />;
    };

    return <Card label="Location">{renderMap()}</Card>;
};

export default LocationCard;
