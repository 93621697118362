import { useState } from 'react';
import { AutoGraph } from '@mui/icons-material';
import { useFlags } from 'launchdarkly-react-client-sdk';
import useDataVisDashboardUrl from 'hooks/queries/useDataVisDashboardUrl';
import useDataVisDashboardOptions from 'hooks/queries/useDataVisDashboardOptions';
import PageLayout from 'domains/core/components/PageLayout';
import Select from 'domains/core/components/Select';
import ErrorSnackbar from 'domains/core/components/Snackbars/ErrorSnackbar';
import LookerEmbed from './LookerEmbed';
import PowerBIEmbed from './PowerBIEmbed';
import ContactsDashboard from './dashboards/ContactsDashboard';

/**
 * Dashboard page for displaying important data elements.
 *
 * @returnsThe React node created by this component.
 */
const InsightsPage = () => {
    const [dashboard, setDashboard] = useState<string>('');
    const { powerbiEnabled: isPowerBIEnabled, dashboardsPoc: hasDashboardsPOCFlag } = useFlags();
    const { data: dashboardValues } = useDataVisDashboardOptions({ onSuccess: (data) => setDashboard(data[0].value) });

    const customDashboardName = 'Custom Contacts';
    const { data, isSuccess, error, isError } = useDataVisDashboardUrl(isPowerBIEnabled, dashboard, {
        enabled: Boolean(dashboard) && dashboard !== customDashboardName,
    });

    const getDashboardOptions = () => {
        if (!dashboardValues) return [];

        if (hasDashboardsPOCFlag) {
            return [...dashboardValues, { label: 'Custom Contacts', value: 'Custom Contacts' }];
        }
        return dashboardValues;
    };
    const dashboardOptions = getDashboardOptions();

    const renderDashboard = () => {
        if (!isSuccess || dashboard === customDashboardName) return;

        if (isPowerBIEnabled) {
            const embedToken = data?.reportDetails?.embedToken?.token;
            const embedUrl = data?.reportDetails?.embedUrl?.embedUrl;
            return <PowerBIEmbed embedURL={embedUrl} embedToken={embedToken} />;
        } else {
            return <LookerEmbed lookerEmbedURL={data.url} />;
        }
    };

    return (
        <PageLayout
            header="insights"
            headerIcon={<AutoGraph fontSize="inherit" />}
            headerAction={
                <Select
                    fullWidth={false}
                    label="Dashboards"
                    sx={{ textTransform: 'capitalize', width: '12rem' }}
                    value={dashboard}
                    values={dashboardOptions}
                    onChange={(e: any) => setDashboard(e.target.value)}
                />
            }
        >
            {isError && <ErrorSnackbar errorMessage={error.message} />}
            {isSuccess && renderDashboard()}
            {dashboard === customDashboardName && <ContactsDashboard />}
        </PageLayout>
    );
};

export default InsightsPage;
