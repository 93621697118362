import LineGraph from 'pages/InsightsPage/visualizations/LineGraph';
import Card from '../Card';
import useContactUnsubscribes from './useContactUnsubscribes';

const UnsubscribesCard = () => {
    const { data, isError, isLoading } = useContactUnsubscribes();

    const renderGraph = () => {
        if (isLoading) return 'Loading...';
        if (isError) return 'Something went wrong.';
        // TODO: figure out why this check is necessary
        if (data?.data?.length) {
            return (
                <LineGraph
                    bottomLegendLabel="unsubscribe date"
                    data={[data]}
                    leftLegendLabel="unsubscribe total"
                    tickValues="every 3 months"
                    tooltipLabel="total contact count"
                />
            );
        }
    };

    return <Card label="Unsubscribes over Time">{renderGraph()}</Card>;
};

export default UnsubscribesCard;
