import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Typography } from '@mui/material';
import { useHistory } from 'react-router';
import { LandingPage, Template, ContentType } from 'domains/content/types';
import DropdownButton from 'domains/core/components/DropdownButton';
import EmptyStateLayout from 'domains/core/components/EmptyStateLayout';
import PageLayout from 'domains/core/components/PageLayout';
import SuccessSnackbar from 'domains/core/components/Snackbars/SuccessSnackbar';
import ContentIcon from 'domains/core/components/SvgIcons/ContentIcon';
import MutationKeys from 'hooks/mutations/keys';
import useLandingPages from 'hooks/queries/useLandingPages';
import useTemplates from 'hooks/queries/useTemplates';
import useMutationStatus from 'hooks/useMutationStatus';
import useQueryParams from 'hooks/useQueryParams';
import { MutationStatus, URLPaths } from 'models/enums';
import ContentTable from './ContentTable';

const contentTypeToLabel: Record<ContentType, string> = {
    [ContentType.EMAIL]: ContentType.EMAIL,
    [ContentType.PAGE]: 'landing page',
};

const ContentPage = () => {
    const history = useHistory();

    const templatesQuery = useTemplates();
    const { data: templatesData, isError: isTemplatesError, isSuccess: isTemplatesSuccess } = templatesQuery;

    const landingPagesQuery = useLandingPages();
    const {
        data: landingPagesData,
        isError: isLandingPagesError,
        isSuccess: isLandingPagesSuccess,
    } = landingPagesQuery;

    const createLandingPageStatus = useMutationStatus(MutationKeys.CREATE_LANDING_PAGE);
    const createTemplateStatus = useMutationStatus(MutationKeys.CREATE_TEMPLATE);
    const updateLandingPageStatus = useMutationStatus(MutationKeys.UPDATE_LANDING_PAGE);
    const updateTemplateStatus = useMutationStatus(MutationKeys.UPDATE_TEMPLATE);

    const query = useQueryParams();
    const type: ContentType = (query.get('type') as ContentType) ?? ContentType.EMAIL;

    const isEmpty =
        (type === ContentType.EMAIL && isTemplatesSuccess && !templatesData?.length) ||
        (type === ContentType.PAGE && isLandingPagesSuccess && !landingPagesData?.length);
    const isError = isTemplatesError || isLandingPagesError;

    const onClickEmail = () => history.push(`${URLPaths.CONTENT_CREATE_EMAIL}?id=default`);
    const onClickPage = () => {
        history.push(`${URLPaths.CONTENT_CREATE_PAGE}?id=default`);
    };

    const dropdownOptions = [
        {
            label: 'email',
            onClick: onClickEmail,
        },
        {
            label: 'landing page',
            onClick: onClickPage,
        },
    ];

    const createButton = (
        <DropdownButton
            aria-label="Choose content type to create"
            data-testid="content-create-button"
            label="Create"
            variant="outlined"
            options={dropdownOptions}
            icon={<ContentIcon />}
        />
    );

    if (isEmpty) {
        return (
            <EmptyStateLayout
                caption="content"
                header="let's design your email"
                guidingText="You currently do not have any content saved."
            >
                {createButton}
            </EmptyStateLayout>
        );
    }

    return (
        <PageLayout
            header="content"
            headerIcon={<ContentIcon fontSize="inherit" />}
            headerAction={createButton}
            headerOthers={
                Boolean(landingPagesData?.length) && (
                    <DropdownButton
                        sx={{ textTransform: 'capitalize' }}
                        aria-label="Choose which type of content to view"
                        label={contentTypeToLabel[type]}
                        variant="text"
                        options={[
                            {
                                label: contentTypeToLabel[ContentType.EMAIL],
                                onClick: () => history.push(`${URLPaths.CONTENT}?type=email`),
                                selected: type === ContentType.EMAIL,
                            },
                            {
                                label: contentTypeToLabel[ContentType.PAGE],
                                onClick: () => history.push(`${URLPaths.CONTENT}?type=page`),
                                selected: type === ContentType.PAGE,
                            },
                        ]}
                        icon={<KeyboardArrowDownIcon />}
                    />
                )
            }
        >
            {createTemplateStatus === MutationStatus.SUCCESS && (
                <SuccessSnackbar successMessage="Email successfully saved." />
            )}
            {updateTemplateStatus === MutationStatus.SUCCESS && (
                <SuccessSnackbar successMessage="Email successfully updated." />
            )}
            {createLandingPageStatus === MutationStatus.SUCCESS && (
                <SuccessSnackbar successMessage="Landing page successfully saved." />
            )}
            {updateLandingPageStatus === MutationStatus.SUCCESS && (
                <SuccessSnackbar successMessage="Landing page successfully updated." />
            )}
            {isError && (
                <Typography>There was a problem loading the content. Please try refreshing the page.</Typography>
            )}
            {type === ContentType.EMAIL && isTemplatesSuccess && (
                <ContentTable<Template> contentQuery={templatesQuery} />
            )}
            {type === ContentType.PAGE && isLandingPagesSuccess && (
                <ContentTable<LandingPage> contentQuery={landingPagesQuery} />
            )}
        </PageLayout>
    );
};

export default ContentPage;
