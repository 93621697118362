export const brandColors = {
    stone: '#FAFAFA',
    lightMint: '#EEFBFB',
    cumin: '#FFD266',
    paloma: '#FEF4EF',
    water: '#EBF2FE',
    lime: '#13D581',
    mint: '#E0F3F0',
    garlic: '#FFF6E9',
    radish: '#FCCFB0',
    mineral: '#437EF6',
};

export const themeColors = {
    primary: {
        main: '#123133',
        light: '#c0dada',
        dark: '#001D1F',
        contrastText: '#ffffff',
        containedHoverBackground: '#0d2224',
        outlinedHoverBackground: 'rgba(231, 250, 245, 0.7)',
        outlinedRestingBackground: '#123133',
    },
    secondary: {
        main: '#415ACE',
        light: '#F3FCFC',
        dark: '#293DAB',
        contrastText: '#ffffff',
        containedHoverBackground: '#293DAB',
        outlinedHoverBackground: 'rgb(65, 90, 206, 0.1)',
        outlinedRestingBackground: '#ffffff',
    },
    text: {
        primary: '#123133',
        secondary: '#2f595c',
        disabled: '#95a6a7',
    },
    action: {
        active: '#00845b',
        hover: '#F3FCFC',
        selected: '#245936',
        disabled: 'rgba(149, 166, 167, 0.5)',
        disabledBackground: 'rgba(149, 166, 167, 0.5)',
        focus: 'rgba(255, 255, 255, 0.2)',
    },
    error: {
        main: '#d8302b',
        dark: '#bc1d17',
        light: '#ffced2',
        containedHoverBackground: '#97221e',
        outlinedHoverBackground: 'rgba(216, 48, 43, 0.08)',
        outlinedRestingBorder: 'rgba(216, 48, 43, 0.5)',
        content: '#561311',
        background: '#fbeaea',
    },
    info: {
        main: brandColors.mineral,
        dark: '#415ace',
        light: '#6ab0ff',
        containedHoverBackground: '#2f58ac',
        outlinedRestingBorder: 'rgba(67, 126, 246, 0.5)',
        outlinedHoverBackground: 'rgba(67, 126, 246, 0.08)',
        content: '#1b3262',
        background: '#ecf2fe',
    },
    warning: {
        main: brandColors.cumin,
        dark: '#f06500',
        light: '#ffdeae',
        contrastText: '#472001',
        containedHoverBackground: '#a64b01',
        outlinedRestingBorder: 'rgba(237, 108, 2, 0.5)',
        outlinedHoverBackground: '#a64c01',
        content: '#66481a',
        background: '#fff7ec',
    },
    success: {
        main: '#29a831',
        dark: '#006600',
        light: '#a0dba0',
        contrastText: '#fff',
        containedHoverBackground: '#1d7622',
        outlinedRestingBorder: 'rgba(41, 168, 49, 0.5)',
        outlinedHoverBackground: 'rgba(41, 168, 49, 0.08)',
        content: '#104314',
        background: '#eaf6ea',
    },
    other: {
        divider: '#c7c7c7',
        outlineBorder: '#ffffff',
        standardInputLine: '#ffffff',
        backdropOverlay: 'rgba(0, 29, 31, 0.5)',
        ratingActive: '#ffb400',
        snackbarBackground: '#323232',
        tableBackground: brandColors.stone,
        tableHover: '#EEEEEE',
        ...brandColors,
    },
};
