import { ResponsiveLine } from '@nivo/line';
import { themeColors } from 'colors';

const LineGraph = ({ data, bottomLegendLabel, leftLegendLabel, tickValues, tooltipLabel }: any) => (
    <div style={{ height: '400px', width: '100%' }}>
        <ResponsiveLine
            axisBottom={{
                tickSize: 0,
                tickPadding: 5,
                tickRotation: 0,
                legend: bottomLegendLabel,
                legendOffset: 36,
                legendPosition: 'middle',
                truncateTickAt: 0,
                tickValues: tickValues ?? 'monthly',
                format: '%b %Y',
            }}
            axisLeft={{
                tickSize: 0,
                tickPadding: 5,
                tickRotation: 0,
                legend: leftLegendLabel,
                legendOffset: -60,
                legendPosition: 'middle',
                truncateTickAt: 0,
                format: (value) => value.toLocaleString(),
            }}
            colors={{ scheme: 'set2' }}
            data={
                data ?? [
                    {
                        id: 'japan',
                        data: [
                            {
                                x: '9/23',
                                y: 17,
                            },
                            {
                                x: '10/23',
                                y: 180,
                            },
                            {
                                x: '11/23',
                                y: 174,
                            },
                            {
                                x: '12/23',
                                y: 96,
                            },
                            {
                                x: '1/24',
                                y: 130,
                            },
                            {
                                x: '2/24',
                                y: 250,
                            },
                            {
                                x: '3/24',
                                y: 19,
                            },
                            {
                                x: '4/24',
                                y: 193,
                            },
                            {
                                x: '5/24',
                                y: 18,
                            },
                            {
                                x: '6/24',
                                y: 54,
                            },
                            {
                                x: '7/24',
                                y: 32,
                            },
                            {
                                x: '8/24',
                                y: 187,
                            },
                        ],
                    },
                ]
            }
            enableGridX={false}
            margin={{
                top: 50,
                right: 50,
                bottom: bottomLegendLabel ? 60 : 40,
                left: 80,
            }}
            pointBorderColor={{ from: 'serieColor' }}
            pointBorderWidth={5}
            pointColor={{ theme: 'background' }}
            pointSize={2}
            pointLabel="data.yFormatted"
            pointLabelYOffset={-12}
            tooltip={({ point }) => (
                <div
                    style={{
                        background: 'white',
                        border: `1px solid ${themeColors.other.divider}`,
                        borderRadius: '6px',
                        fontSize: '12px',
                        padding: '8px',
                    }}
                >
                    <div style={{ fontWeight: 'bold' }}>{point.data.xFormatted}</div>
                    <div>
                        {tooltipLabel} - {point.data.yFormatted}
                    </div>
                </div>
            )}
            useMesh={true}
            xFormat="time:%m/%d/%Y"
            xScale={{
                format: '%Y-%m-%d',
                precision: 'month',
                type: 'time',
                useUTC: false,
            }}
            yFormat=" >-,.2~f"
            yScale={{
                type: 'linear',
                min: 'auto',
                max: 'auto',
            }}
        />
    </div>
);

export default LineGraph;
