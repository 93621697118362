import { Config } from 'models/types';

const configLocalStaging: Config = {
    API_URL: 'https://cured-alpha-staging-api.curedhealth.io/',
    AUTH0_DOMAIN: 'cured-staging.us.auth0.com',
    AUTH0_CLIENT_ID: 'RBZQaEOVqIl9WcOQIsQcp7bO2iWlS8QM',
    AUTH0_AUDIENCE: 'https://cured-alpha-staging-api.curedhealth.io/',
    AUTH0_AUDIENCE_ENDPOINT: 'https://cured.health/roles',
    AUTH0_REDIRECT_URI: 'http://localhost:3000',
    AUTH0_HOME_URI: 'http://localhost:3000',
    LAUNCH_DARKLY_CLIENT_SIDE_ID: '60d4a919bc3c320d1a465ec4',
    SATISMETER_WRITE_KEY: 'I77DYSXJCUmPhSeh8Fbp1oPSByZkDWOU',
    SATISMETER_ENDPOINT: 'https://app.satismeter.com/js',
    FULLSTORY_ORG_ID: 'o-1YWP06-na1',
};

export default configLocalStaging;
