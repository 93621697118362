import { Box, Stack } from '@mui/material';
import PageHeader from 'domains/core/components/PageHeader';

export type Props = {
    header: React.ReactNode;
    headerIcon: React.ReactNode;
    headerAction: React.ReactNode;
    headerOthers?: React.ReactNode;
    children?: React.ReactNode;
};

const PageLayout = ({ header, headerIcon, headerAction, headerOthers, children }: Props) => (
    <Stack width="100%" height="100%" mt={8} rowGap={8}>
        <Stack direction="row" justifyContent="space-between">
            <PageHeader icon={headerIcon} header={header} headerOthers={headerOthers} />
            {headerAction}
        </Stack>
        <Box height="100%">{children}</Box>
    </Stack>
);

export default PageLayout;
