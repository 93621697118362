import PieChart from 'pages/InsightsPage/visualizations/PieChart';
import Card from '../Card';
import useContactSex from './useContactSex';

const SexCard = () => {
    const { data, isError, isLoading } = useContactSex();

    const renderChart = () => {
        if (isLoading) return 'Loading...';
        if (isError) return 'Something went wrong.';
        return <PieChart data={data} />;
    };

    return <Card label="Sex">{renderChart()}</Card>;
};

export default SexCard;
