import { UseQueryOptions } from 'react-query';
import { CuredApiPaths } from 'models/enums';
import useQuery from 'hooks/useQuery';

const useContactDomains = (reactQueryOptions?: UseQueryOptions<any, Error>) => {
    const path = CuredApiPaths.CONTACTS_DOMAINS;

    return useQuery('contacts-domains', path, reactQueryOptions);
};

export default useContactDomains;
