import { Typography } from '@mui/material';
import { DateTime } from 'luxon';
import { SQLType } from 'models/enums';
import {
    DateRange,
    isDateRangeOperator as isDateRangeOperatorType,
    isDateRangeType,
    isRelativeDateRangeType,
    SegmentDemographic,
} from 'domains/segments/types';
import {
    getInclusionOperatorFromAPIToUI,
    isRelativeDateRangeLast,
    isRelativeDateRangeNext,
    isRelativeDateRangeToday,
} from 'domains/segments/utils';

export const secondarySpanProps = {
    color: 'secondary',
    component: 'span',
};

export const renderValue = (value: SegmentDemographic['value']) => {
    const isValueArray = Array.isArray(value);

    if (isValueArray) {
        const hasTwoValues = value.length === 2;

        if (hasTwoValues) {
            return (
                <>
                    <Typography {...secondarySpanProps}>{` ${value[0]}`}</Typography>
                    <Typography component="span">{' and '}</Typography>
                    <Typography {...secondarySpanProps}>{`${value[1]}`}</Typography>
                </>
            );
        }

        const renderCommaSeparatedValues = (values: string[] | number[]) => {
            const lastValue = values[values.length - 1];
            const commaSeparatedValues = values.slice(0, -1).join(', ');

            return (
                <>
                    {commaSeparatedValues && <>{commaSeparatedValues},</>}
                    <Typography component="span">{commaSeparatedValues && ' and '}</Typography>
                    {lastValue}
                </>
            );
        };

        return <Typography {...secondarySpanProps}> {renderCommaSeparatedValues(value)}</Typography>;
    }

    return (
        <Typography {...secondarySpanProps}>
            <> {value}</>
        </Typography>
    );
};

const renderDateRange = (startDate: string, endDate: string) => (
    <>
        {startDate}
        <Typography component="span" color="primary">
            {' - '}
        </Typography>
        {endDate}
    </>
);

// TODO: revisit this and refactor for readability
const FilterDetails = ({ name, operator, sqlType, value }: SegmentDemographic) => {
    const isDateRangeOperator = isDateRangeOperatorType(operator);

    const isDateRangeValue = isDateRangeType(value);
    const isRelativeDateRangeValue = isRelativeDateRangeType(value);
    const isToday = isRelativeDateRangeToday(value);
    const isNext = isRelativeDateRangeNext(value);
    const isLast = isRelativeDateRangeLast(value);
    const isMultipleValue = Array.isArray(value);

    const dateStart = isDateRangeValue && !isMultipleValue && DateTime.fromISO((value as DateRange).start);
    const dateEnd = isDateRangeValue && !isMultipleValue && DateTime.fromISO((value as DateRange).end);
    const datetime =
        !isDateRangeValue && !isMultipleValue && DateTime.fromISO(value as string).toFormat('LL/dd/yyyy h:mm a ZZZZ');

    const dateRange =
        isDateRangeValue && renderDateRange(dateStart.toFormat('LL/dd/yyyy'), dateEnd.toFormat('LL/dd/yyyy'));
    const datetimeRange =
        isDateRangeValue &&
        renderDateRange(dateStart.toFormat('LL/dd/yyyy h:mm a ZZZZ'), dateEnd.toFormat('LL/dd/yyyy h:mm a ZZZZ'));

    const renderedValue = renderValue(value);

    const dateRangeText = isToday
        ? 'today'
        : isRelativeDateRangeValue &&
          `${value.duration.value} ${value.duration.unit}${value.duration.value > 1 ? 's' : ''}`;

    return (
        <>
            <Typography component="span" color="secondary">
                {name}{' '}
            </Typography>
            <Typography component="span">
                {isToday ? 'is ' : `${getInclusionOperatorFromAPIToUI(operator)} `}
                {isNext && `the next `}
                {isLast && `the last `}
            </Typography>
            <Typography component="span" color="secondary">
                {isDateRangeOperator ? (
                    <>
                        {sqlType === SQLType.TIMESTAMP_WITH_TIME_ZONE ? datetimeRange : dateRange}
                        {dateRangeText}
                    </>
                ) : (
                    <>{sqlType === SQLType.TIMESTAMP_WITH_TIME_ZONE ? datetime : renderedValue}</>
                )}
            </Typography>
        </>
    );
};

export default FilterDetails;
