// This file used declaration merging, so we need to use interfaces for that.
/* eslint-disable @typescript-eslint/consistent-type-definitions */

import { LicenseInfo } from '@mui/x-license-pro';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Switch, Route, useLocation, useHistory } from 'react-router-dom';
import { ReactQueryDevtools } from 'react-query/devtools';
import { useAuth0 } from '@auth0/auth0-react';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
// Needed for type support - https://mui.com/x/react-date-pickers/getting-started/#typescript
import type {} from '@mui/x-date-pickers-pro/themeAugmentation';
import type {} from '@mui/x-date-pickers/themeAugmentation';
import { LocalizationProvider as LocalizationProviderPro } from '@mui/x-date-pickers-pro';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { ThemeProvider, Theme, StyledEngineProvider } from '@mui/material/styles';
import { ReactFlowProvider } from 'reactflow';
import useConfig from 'hooks/useConfig';
import Background from 'domains/core/components/Background';
import CurationPage from 'domains/campaigns/pages/CurationPage';
import CurationsPage from 'domains/campaigns/pages/CurationsPage';
import CampaignDetailsPage from 'domains/campaigns/pages/CurationsPage/CampaignDetailsPage';
import NavigationPanel from 'domains/core/components/NavigationPanel';
import SegmentsPage from 'domains/segments/pages/SegmentsPage';
import SegmentCreatePage from 'domains/segments/pages/SegmentCreatePage';
import ValueCalculatorPage from 'domains/value/pages/ValueCalculatorPage';
import ValueCalculatorCreatePage from 'domains/value/pages/ValueCalculatorCreatePage';
import ValueCalculatorDetailsPage from 'domains/value/pages/ValueCalculatorDetailsPage';
import { URLPaths } from './models/enums';
import ContactsPage from './pages/ContactsPage';
import ContentPage from './pages/ContentPage';
import ContentEmailBuilderPage from './pages/ContentEmailBuilderPage';
import ContentPageBuilderPage from './pages/ContentPageBuilderPage';
import FinishSignupPage from './pages/FinishSignupPage';
import HomePage from './pages/HomePage';
import InsightsPage from './pages/InsightsPage';
import LoginPage from './pages/LoginPage';
import SMSCurationPage from './pages/SMSCurationPage';
import UnsubscribeLandingPage from './pages/UnsubscribeLandingPage';
import { minimizeLeftNavPanel } from './state/appVisual/appVisualActions';
import theme from './theme';
import './App.css';
import useFeatureSettings from 'hooks/queries/useFeatureSettings';
import { injectSatismeter } from 'helpers/injectSatismeter/injectSatismeter';
import { FullStory } from '@fullstory/browser';

LicenseInfo.setLicenseKey(
    'e170fcbdbebec2a3b140ec9f639f8b61Tz02MTc4NSxFPTE3MTAxMDY0OTY0NzksUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI='
);

// The data-sources routes are disabled for now.
// import DataSourcesPage from './pages/DataSourcesPage';
// import DataSourcesConfigurePage from './pages/DataSourcesConfigurePage';
// import FileInfoPage from './pages/FileInfoPage';

interface OtherOptions {
    divider: string;
    outlineBorder: string;
    standardInputLine: string;
    backdropOverlay: string;
    ratingActive: string;
    snackbarBackground: string;
    tableBackground: string;
    tableHover: string;
    stone: string;
    lightMint: string;
    cumin: string;
    paloma: string;
    water: string;
    lime: string;
    mint: string;
    garlic: string;
    radish: string;
    mineral: string;
    newPanelBackground: string;
}
declare module '@mui/styles/defaultTheme' {
    interface DefaultTheme extends Theme {}
}
// 2. Notify TypeScript about the new color in the palette
declare module '@mui/material/styles' {
    interface SimplePaletteColorOptions {
        containedHoverBackground?: string;
        outlinedHoverBackground?: string;
        outlinedRestingBackground?: string;
        outlinedRestingBorder?: string;
        content?: string;
        background?: string;
    }
    interface Palette {
        other: OtherOptions;
    }
    interface PaletteOptions {
        other: OtherOptions;
    }
    interface SimplePaletteColorOptions {
        content?: string;
    }
    interface PaletteColor {
        content?: string;
    }
}
declare module '@mui/material/styles' {
    interface TypographyVariants {
        avatarInitials: React.CSSProperties;
        buttonLarge: React.CSSProperties;
        buttonMedium: React.CSSProperties;
        buttonSmall: React.CSSProperties;
        inputLabel: React.CSSProperties;
        inputText: React.CSSProperties;
        helperText: React.CSSProperties;
        tooltip: React.CSSProperties;
    }
    // allow configuration using `createTheme`
    interface TypographyVariantsOptions {
        avatarInitials?: React.CSSProperties;
        buttonLarge?: React.CSSProperties;
        buttonMedium?: React.CSSProperties;
        buttonSmall?: React.CSSProperties;
        inputLabel?: React.CSSProperties;
        inputText?: React.CSSProperties;
        helperText?: React.CSSProperties;
        tooltip?: React.CSSProperties;
    }
}
declare module '@mui/material/Typography' {
    interface TypographyPropsVariantOverrides {
        buttonLarge: true;
        buttonMedium: true;
        buttonSmall: true;
        avatarInitials: true;
        inputLabel: true;
        inputText: true;
        helperText: true;
        tooltip: true;
    }
}

const getAppBodyClassName = (path: string, isAuthenticated: boolean) => {
    let className = 'app-body';
    if (path === '' && isAuthenticated) {
        className += ' app-body-home-page';
    } else if (path === '' && !isAuthenticated) {
        className += ' app-body-login-page';
    }
    return className;
};

const knownUserHasNoRoles = (user: any, auth0AudienceEndpoint: string) => {
    const roleCount = user?.[auth0AudienceEndpoint]?.length;
    return roleCount === 0;
};

const App = () => {
    const dispatch = useDispatch();
    const { isAuthenticated, isLoading, loginWithRedirect, user } = useAuth0();
    const history = useHistory();
    const {
        config: { AUTH0_AUDIENCE_ENDPOINT, SATISMETER_WRITE_KEY, SATISMETER_ENDPOINT, FULLSTORY_ORG_ID },
    } = useConfig();

    /*
        If the user tries to access any page other than the homepage(which renders the login button if they are not authenticated),
        the unsubscribe page, which does not require authentication,
        redirect them to the auth0 login page then back to their original destination once authenticated.
    */
    useEffect(() => {
        if (
            !isLoading &&
            !isAuthenticated &&
            history.location.pathname !== URLPaths.HOME_PAGE &&
            history.location.pathname !== URLPaths.UNSUBSCRIBE
        ) {
            loginWithRedirect({
                appState: {
                    returnTo: history.location.pathname,
                },
            });
        }
    }, [history.location.pathname, isAuthenticated, isLoading, loginWithRedirect]);

    /**
     * Analytics Integration
     * 1. Satismeter integration
     * Link : https://app.satismeter.com/install-instructions/6613de55ad1b6ce6094992f0?installAuthKey=ts11YBj5i32HzMA4UCHjMFbNatft1BB7
     * 2. Fullstory monitoring user identification
     */
    useEffect(() => {
        if (!isAuthenticated || !user) return;
        const { name, email } = user;
        if (SATISMETER_ENDPOINT && SATISMETER_WRITE_KEY) {
            injectSatismeter(SATISMETER_ENDPOINT);

            (window as any).satismeter({
                writeKey: SATISMETER_WRITE_KEY,
                userId: email,
                traits: {
                    name,
                    email,
                },
            });
        }
        if (FULLSTORY_ORG_ID) {
            FullStory('setIdentity', {
                uid: email,
                properties: {
                    displayName: name,
                    email,
                },
            });
        }
    }, [user, isAuthenticated, SATISMETER_ENDPOINT, SATISMETER_WRITE_KEY, FULLSTORY_ORG_ID]);

    // Delay network call until the user is authenticated.
    const { data: featureSettings, isSuccess: isFeatureSettingsSuccess } = useFeatureSettings({
        enabled: isAuthenticated,
    });

    // The following paths need the left nav panel minimized
    const path = useLocation().pathname.substring(useLocation().pathname.indexOf('/') + 1);

    if (
        (path === '' && !isAuthenticated) ||
        path === URLPaths.CONTENT_CREATE_EMAIL ||
        path === URLPaths.CONTENT_CREATE_PAGE
    ) {
        dispatch(minimizeLeftNavPanel(false));
    }

    // If the user has not been assigned a role yet, redirect them to the FinishSignupPage.
    const userHasNoRoles = knownUserHasNoRoles(user, AUTH0_AUDIENCE_ENDPOINT);
    if (userHasNoRoles) {
        return (
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme}>
                    <div id="app-content">
                        <Route path="/" component={FinishSignupPage} exact />
                    </div>
                </ThemeProvider>
            </StyledEngineProvider>
        );
    }

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <LocalizationProvider dateAdapter={AdapterLuxon}>
                    <LocalizationProviderPro dateAdapter={AdapterLuxon}>
                        <ReactFlowProvider>
                            <Switch>
                                <Route path={URLPaths.UNSUBSCRIBE} component={UnsubscribeLandingPage} exact />
                                <Route path="*">
                                    <div className={getAppBodyClassName(path, isAuthenticated)}>
                                        <NavigationPanel />
                                        <Background>
                                            <Switch>
                                                {!isAuthenticated && (
                                                    <Route path={URLPaths.HOME_PAGE} component={LoginPage} exact />
                                                )}
                                                {isAuthenticated && (
                                                    <Route path={URLPaths.HOME_PAGE} component={HomePage} exact />
                                                )}
                                                {isAuthenticated && (
                                                    <Route path={URLPaths.CURATIONS_LANDING} component={CurationPage} />
                                                )}
                                                {isFeatureSettingsSuccess &&
                                                    featureSettings?.sms_enabled &&
                                                    isAuthenticated && (
                                                        <Route
                                                            path={URLPaths.CURATIONS_SMS}
                                                            component={SMSCurationPage}
                                                            exact
                                                        />
                                                    )}
                                                {isAuthenticated && (
                                                    <Route path={URLPaths.CURATIONS} component={CurationsPage} exact />
                                                )}
                                                {isAuthenticated && (
                                                    <Route
                                                        path={`${URLPaths.CURATIONS}/:campaignId/summary`}
                                                        component={CampaignDetailsPage}
                                                        exact
                                                    />
                                                )}
                                                {isAuthenticated && (
                                                    <Route path={URLPaths.CURATIONS} component={CurationsPage} exact />
                                                )}
                                                {isAuthenticated && (
                                                    <Route
                                                        path={`${URLPaths.CURATIONS}/:campaignId/summary`}
                                                        component={CampaignDetailsPage}
                                                        exact
                                                    />
                                                )}
                                                {isAuthenticated && (
                                                    <Route path={URLPaths.SEGMENTS} component={SegmentsPage} exact />
                                                )}
                                                {isAuthenticated && (
                                                    <Route
                                                        path={URLPaths.SEGMENT_CREATE}
                                                        component={SegmentCreatePage}
                                                        exact
                                                    />
                                                )}
                                                {isAuthenticated && (
                                                    <Route
                                                        path={URLPaths.SEGMENTS_CONTACTS}
                                                        component={ContactsPage}
                                                        exact
                                                    />
                                                )}
                                                {isAuthenticated && (
                                                    <Route path={URLPaths.CONTACTS} component={ContactsPage} exact />
                                                )}
                                                {isAuthenticated && (
                                                    <Route path={URLPaths.CONTENT} component={ContentPage} exact />
                                                )}
                                                {isAuthenticated && (
                                                    <Route
                                                        path={URLPaths.CONTENT_CREATE_EMAIL}
                                                        component={ContentEmailBuilderPage}
                                                        exact
                                                    />
                                                )}
                                                {isAuthenticated && (
                                                    <Route
                                                        path={URLPaths.CONTENT_CREATE_PAGE}
                                                        component={ContentPageBuilderPage}
                                                        exact
                                                    />
                                                )}
                                                {isAuthenticated && (
                                                    <Route path={URLPaths.INSIGHTS} component={InsightsPage} exact />
                                                )}
                                                {isAuthenticated && (
                                                    <Route
                                                        path={URLPaths.VALUE}
                                                        component={ValueCalculatorPage}
                                                        exact
                                                    />
                                                )}
                                                {isAuthenticated && (
                                                    <Route
                                                        path={URLPaths.VALUE_CREATE}
                                                        component={ValueCalculatorCreatePage}
                                                    />
                                                )}
                                                {isAuthenticated && (
                                                    <Route
                                                        path={`${URLPaths.VALUE_DETAILS}/:sendId/summary`}
                                                        component={ValueCalculatorDetailsPage}
                                                        exact
                                                    />
                                                )}
                                            </Switch>
                                        </Background>
                                    </div>
                                </Route>
                            </Switch>
                            <ReactQueryDevtools initialIsOpen={false} position="top-right" />
                        </ReactFlowProvider>
                    </LocalizationProviderPro>
                </LocalizationProvider>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

export default App;
