import { useAuth0 } from '@auth0/auth0-react';
import axios, { AxiosInstance, Method } from 'axios';
import useConfig from 'hooks/useConfig';

/**
 * Gets a function to get the axios instance.
 * @param method the http method
 * @param options the axios options
 * @param authenticated whether or not to get a token and pass the Authorization header
 * @returns an async function that returns an axios instance
 */
const useGetAxiosInstance = (method: Method, options: any = {}, authenticated: boolean = true) => {
    const { getAccessTokenSilently, loginWithRedirect } = useAuth0();
    const {
        config: { API_URL },
    } = useConfig();

    const getApiInstance = async (): Promise<AxiosInstance> => {
        let accessToken;

        if (authenticated) {
            try {
                accessToken = await getAccessTokenSilently();
            } catch (e) {
                if ((e as any).error === 'login_required') {
                    loginWithRedirect();
                }
                if ((e as any).error === 'consent_required') {
                    loginWithRedirect();
                }
                throw e;
            }
        }

        const instance = axios.create({
            method,
            baseURL: API_URL,
            headers: {
                'Content-Type': 'application/json',
                ...(authenticated && { Authorization: `Bearer ${accessToken}` }),
                ...options?.headers,
            },
        });
        return instance;
    };
    return getApiInstance;
};

export default useGetAxiosInstance;
